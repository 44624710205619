import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useRoutes } from "react-router-dom";
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";
import moment from "moment";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { useUserStore } from "../../../store/store";
import useElementSize from "../../../hooks/useElementSize";
// import FeatherIcon from 'feather-icons-react';
import { RxLockClosed } from "react-icons/rx";
import { FiAlertCircle, FiBell } from "react-icons/fi";
import { RiQuestionLine } from "react-icons/ri";
import qs from "qs";

const InquiryListSection = (props) => {
  const navigate = useNavigate();

  const [permission, user] = useUserStore((state) => [state.permission, state.user]);

  // get url params
  const params = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(params.get("page") || 1);

  const [{ width: tableElementWidth }, tableRef] = useElementSize();
  const tableWidth = (() => {
    // console.log("tableElementWidth", tableElementWidth);
    if (window.innerWidth >= 1200) {
      // console.log("1200");
      return 1200 - 40;
    } else if (window.innerWidth >= 992) {
      // console.log("992");
      return 992 - 20;
    } else if (window.innerWidth >= 768) {
      // console.log("768");
      return 768 - 40;
    } else if (window.innerWidth >= 576) {
      // console.log("576");
      return 576 - 80;
    }
    return tableElementWidth - 20;
  })();

  const [inquiries, setInquiries] = useState([]);
  const [inquiryCount, setInquiryCount] = useState(0);
  const [inquiryNext, setInquiryNext] = useState(null);
  const [inquiryPrevious, setInquiryPrevious] = useState(null);

  const [noticeList, setNoticeList] = useState([]);

  const [loading, setLoading] = useState(null);

  const [queryStrings, setQueryStrings] = useState({
    // phone_num: "",
    // search: "",
    // is_notice: "False",
    // mode: "2",
    modes: "1,2,3,7",
    // need_extra_info: "",
    // no_reply: "",
    // my_article: "",
  });

  const [headBanners, setHeadBanners] = useState(null);

  const columns = [
    {
      Header: "제목",
      accessor: "title",
      width: tableWidth > 576 ? tableWidth - 260 : tableWidth - 120, // - 100 - 50 - 120
      Cell: (cellProps) => {
        return (
          <Link to={`/inquiry/detail/${cellProps.row.original.id}?page=${page}`}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div style={{ maxWidth: tableWidth > 576 ? tableWidth - 260 : tableWidth - 120, overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {!!permission?.permission && cellProps.row.original["need_extra_info"] ? <FiAlertCircle size="20" style={{ marginTop: "-4px", color: "#f06548" }} /> : null}

                {(!cellProps.row.original["comment_count"] && moment(cellProps.row.original["written_date"]) > moment().subtract(1, "days")) ||
                (!!cellProps.row.original["latest_comment_date"] && moment(cellProps.row.original["latest_comment_date"]) > moment().subtract(1, "days")) ? (
                  <i className="mdi mdi-new-box fs-22"></i>
                ) : null}

                {cellProps.row.original["is_notice"] ? "[공지] " : ""}

                {!!permission?.permission && !["4", "5", "6"].includes(cellProps.row.original["mode"]) && !cellProps.row.original["is_latest_comment_by_staff"] ? (
                  <FiBell size="20" style={{ marginTop: "-4px", color: "#f06548" }} />
                ) : null}

                {["2", "1"].includes(cellProps.row.original["mode"]) ? <RxLockClosed size="20" style={{ marginTop: "-4px" }} /> : null}

                <span>&nbsp;</span>
                {cellProps.row.original["title"]}
              </div>

              {!["4", "5", "6"].includes(cellProps.row.original["mode"]) && cellProps.row.original["comment_count"] > 0 ? (
                <>
                  <span>&nbsp;</span>
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      width: "20px",
                      height: "20px",
                      borderRadius: "100px",
                      fontSize: "12px",
                      backgroundColor: "#F6475F",
                      color: "white",
                    }}
                  >
                    <div style={{ width: "20px", height: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>{cellProps.row.original["comment_count"]}</div>
                  </div>
                </>
              ) : null}
            </div>
          </Link>
        );
      },
    },
    tableWidth > 576
      ? {
          Header: "작성일",
          accessor: "written_date",
          width: 100,
          Cell: (cellProps) => {
            return moment(cellProps.row.original["written_date"]).format("YYYY-MM-DD");
          },
        }
      : {
          Header: "",
          accessor: "written_date",
          width: 0,
          Cell: (cellProps) => {
            return <span style={{ display: "none" }}>{moment(cellProps.row.original["written_date"]).format("YYYY-MM-DD")}</span>;
          },
        },
    {
      Header: "",
      accessor: "phone_num",
      width: 0,
      Cell: (cellProps) => {
        return <span style={{ display: "none" }}>{cellProps.row.original["phone_num"]}</span>;
      },
    },
    {
      Header: "작성자",
      accessor: "user_full_name",
      accessorFn: (cellProps) => {
        return cellProps.row.original["user_full_name"];
      },
      width: 50,
      Cell: (cellProps) => {
        if (!!cellProps.row.original["user_permission"]?.permission) {
          return <span>관리자</span>;
        }
        let _username = cellProps.row.original["user_full_name"];
        _username = user && cellProps.row.original["user_id"] === String(user.id) ? _username : _username[0] + "*".repeat(_username.length - 1);
        return <span>{_username}</span>;
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    const accessToken = getAccessToken();
    const _subdomain = SUBDOMAIN;

    fetch(`${BACKEND_HOST}/inquiry/articles/?subdomain=${_subdomain}&modes=4,5,6`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.length === 0) return;
        setNoticeList(data);
      })
      .catch((e) => {
        console.log(e);
      });

    let _query = qs.stringify(queryStrings);
    if (_query) _query = `&${_query}`;
    if (page) _query = `${_query}&page=${page}`;

    // const _subdomain = (SUBDOMAIN === "test") ? "" : SUBDOMAIN;
    fetch(`${BACKEND_HOST}/inquiry/article-list/?subdomain=${_subdomain}${_query}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.length === 0) return;
        // console.log(data)
        // let columnName = Object.keys(data[0]);

        // {
        //     "id": 1,
        //     "title": "텟트",
        //     "content": "글글",
        //     "written_date": "2023-03-08T11:44:12.194227",
        //     "image_group_key": "9d8cd9bf-cda8-4f61-911f-52697497c587",
        //     "address": "부산 금정구 중앙대로1685번길 22",
        //     "site": 9,
        //     "user": {id, username, ...},
        //     "images": [
        //         {id, image, ...}
        //     ]
        // }
        // setAllInquiries(data.results);
        setInquiryCount(data.count);
        setInquiryNext(data.next);
        setInquiryPrevious(data.previous);
        if (data.results) setInquiries(data.results);
        else setInquiries([]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }, [queryStrings, page]);

  useEffect(() => {
    let _subdomain = window.location.host.split(".")[0];
    console.log("subdomain", _subdomain);
    if (_subdomain.startsWith("localhost")) _subdomain = "test";

    fetch(`${BACKEND_HOST}/inquiry/head-banner/`, {
      // fetch(`${BACKEND_HOST}/media/inquiry/inquiry_head.json?cache_date=${moment().format('YYYY-MM-DD_HH_mm')}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.datas.length === 0) return;

        const banners = data.datas.filter((item) => item.subdomain === _subdomain);
        console.log(banners);
        if (banners && banners.length) setHeadBanners(banners);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <Container className="mt-4">
        {!!headBanners && headBanners.length
          ? headBanners.map((headBanner, i) => {
              return (
                <Row key={i}>
                  <Card id="inquiryBanner">
                    <div className="card-body pt-0">
                      {headBanner.type === "imagebanner" ? (
                        <div className="row" style={{}}>
                          <a href={headBanner.url} target="_self" rel="noreferrer">
                            <div>
                              <img
                                src={headBanner.background}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "auto",
                                }}
                              />
                            </div>
                          </a>
                          <div
                            className="banner-item-group"
                            style={{
                              position: "absolute",
                              bottom: "17%",
                              left: "8%",
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {headBanner.subitems && headBanner.subitems.length > 1 ? (
                              <>
                                <div className="banner-item" style={{ width: "16%" }}>
                                  <a href={headBanner.subitems[0].url} target="_blank">
                                    <img src={headBanner.subitems[0].background} alt="" style={{ width: "100%" }} />
                                  </a>
                                </div>
                                <div className="banner-item" style={{ width: "16%" }}>
                                  <a href={headBanner.subitems[1].url} target="_blank">
                                    <img src={headBanner.subitems[1].background} alt="" style={{ width: "100%" }} />
                                  </a>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            minHeight: "200px",
                            width: "100%",
                            position: "relative",
                            padding: "20px",
                            borderRadius: "10px",
                            // backgroundColor: "#f5f5f5",
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "14px" }}>
                            <RiQuestionLine style={{ fontSize: "20px", marginRight: "5px" }} />
                            <h3 style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "#333", margin: "0", padding: 0 }}>{headBanner.title}</h3>
                          </div>
                          <div
                            className="banner-item-group"
                            style={{
                              // position: "absolute",
                              // bottom: "17%",
                              // left: "8%",
                              // width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "12px",
                              flexWrap: "wrap",
                            }}
                          >
                            {headBanner.subitems && headBanner.subitems.length > 1
                              ? headBanner.subitems.map((item, i) => {
                                  return (
                                    <a
                                      key={i}
                                      href={item.url}
                                      target="_self"
                                      style={{
                                        width: tableWidth > 576 ? "18%" : tableWidth > 380 ? "48%" : "100%",
                                        minWidth: "110px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: "#333",
                                      }}
                                    >
                                      <div
                                        className="banner-item"
                                        style={{
                                          width: "100%",
                                          minWidth: "120px",
                                          height: "58px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#fff",
                                          borderRadius: "5px",
                                          padding: "5px 10px",
                                          // borderColor: "#eee", borderWidth: "1px", borderStyle: "solid",
                                          filter: "drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2))",
                                        }}
                                      >
                                        <span>{item.name}</span>
                                      </div>
                                    </a>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </Row>
              );
            })
          : null}
        <Row className="justify-content-center">
          <Col>
            <Card id="customerList">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <div className="col-sm-auto">
                    <div>
                      <h4 className="card-title mb-0 flex-grow-1">문의</h4>
                    </div>
                  </div>
                  <div className="col-sm-3 ms-auto">
                    <div className="d-flex justify-content-sm-end gap-2">
                      <Button
                        className="btn btn-soft-primary add-btn me-1"
                        onClick={() => {
                          navigate(`/inquiry/form/${0}`);
                        }}
                        id="write-btn"
                      >
                        글쓰기
                      </Button>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <div className="card-body pt-0">
                <div>
                  <div className="d-flex flex-row mb-2 gap-2">
                    {permission?.permission ? (
                      <>
                        <div
                          className="me-1"
                          style={{
                            borderRadius: 100,
                            padding: "0.4rem 0.8rem",
                            cursor: "pointer",
                            userSelect: "none",
                            backgroundColor: queryStrings.no_reply ? "#3577f1" : "#e9ecef",
                            color: queryStrings.no_reply ? "#fff" : "#000",
                          }}
                          onClick={() => {
                            // setFilterParams({ ...filterParams, no_reply: !filterParams.no_reply });
                            setQueryStrings({ ...queryStrings, no_reply: !queryStrings.no_reply ? "True" : "" });
                          }}
                        >
                          답변필요
                        </div>
                        <div
                          className="me-1"
                          style={{
                            borderRadius: 100,
                            padding: "0.4rem 0.8rem",
                            userSelect: "none",
                            cursor: "pointer",
                            backgroundColor: queryStrings.need_care ? "#3577f1" : "#e9ecef",
                            color: queryStrings.need_care ? "#fff" : "#000",
                          }}
                          onClick={() => {
                            // setFilterParams({ ...filterParams, need_care: !filterParams.need_care });
                            setQueryStrings({ ...queryStrings, need_extra_info: !queryStrings.need_care ? "True" : "False", need_care: !queryStrings.need_care });
                          }}
                        >
                          확인필요
                        </div>
                      </>
                    ) : null}

                    <div
                      className="me-1"
                      style={{
                        borderRadius: 100,
                        padding: "0.4rem 0.8rem",
                        userSelect: "none",
                        cursor: "pointer",
                        backgroundColor: queryStrings.my_article ? "#3577f1" : "#e9ecef",
                        color: queryStrings.my_article ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        // setFilterParams({ ...filterParams, my_article: !filterParams.my_article });
                        setQueryStrings({ ...queryStrings, phone_num: !queryStrings.my_article ? user.phone : undefined, my_article: !queryStrings.my_article });
                      }}
                    >
                      내가쓴글
                    </div>
                  </div>

                  <Col md={3}>
                    <div className={"search-box me-2 mb-2 d-inline-block col-12"}>
                      <input
                        onChange={(e) => {
                          // setValue(e.target.value);
                          // onChange(e.target.value);
                          setQueryStrings((prev) => ({ ...prev, search: e.target.value }));
                        }}
                        id="search-bar-0"
                        type="text"
                        className="form-control search /"
                        placeholder={`${inquiryCount} Search...`}
                        value={queryStrings.search || ""}
                      />
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </Col>

                  {inquiries && inquiries.length ? (
                    <>
                      {/* // <TableContainer
                    //   defaultPage={page}
                    //   ref={tableRef}
                    //   columns={columns}
                    //   data={inquiries || []}
                    //   isGlobalFilter={true}
                    //   isAddUserList={false}
                    //   customPageSize={10}
                    //   className="custom-header-css"
                    //   isCustomerFilter={true}
                    //   theadClass="table-light text-muted fs-13"
                    //   onChangePage={(page) => setPage(page)}
                    // /> */}
                      <TableContainer
                        // defaultPage={page}
                        ref={tableRef}
                        columns={columns}
                        data={[...noticeList, ...inquiries].reverse() || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        isCustomerFilter={false}
                        customPageSize={100}
                        className="custom-header-css"
                        theadClass="table-light text-muted fs-13"
                      />
                      {inquiryNext || inquiryPrevious ? (
                        // <Row className="d-flex  justify-content-center align-items-center p-2">
                        <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {/* <Col className="col-md-auto"> */}
                          {/* <Col> */}
                          <div className="d-flex gap-1">
                            <Button
                              // color="primary"
                              onClick={() => {
                                if (!inquiryPrevious) return;
                                setPage(Number(page) - 1);
                              }}
                              disabled={!inquiryPrevious}
                              style={{ color: "#F6475F", backgroundColor: "transparent", border: "none", fontSize: "18px", fontFamily: "Gmarket-M", boxShadow: "none" }}
                            >
                              {"<"}
                            </Button>
                          </div>
                          {/* </Col> */}
                          {/* <Col className="col-md-auto d-block d-md-block"> */}
                          {/* <Col> */}
                          <span style={{ fontFamily: "Gmarket-M", width: "90px", textAlign: "center" }}>
                            {Number(page)} / {Math.ceil(inquiryCount / 15)}
                          </span>
                          {/* </Col> */}

                          {/* <Col className="col-md-auto"> */}
                          {/* <Col> */}
                          <div className="d-flex gap-1">
                            <Button
                              // color="primary"
                              onClick={() => {
                                if (!inquiryNext) return;
                                setPage(Number(page) + 1);
                              }}
                              disabled={!inquiryNext}
                              style={{ color: "#F6475F", backgroundColor: "transparent", border: "none", fontSize: "18px", fontFamily: "Gmarket-M", boxShadow: "none" }}
                            >
                              {">"}
                            </Button>
                          </div>
                          {/* </Col> */}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <Loader loading={loading} />
                  )}
                </div>

                <ToastContainer closeButton={false} />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InquiryListSection;
