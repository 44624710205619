import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table, Col, Row } from 'reactstrap';
import { BACKEND_HOST } from "../../../api";
import { getAccessToken } from "../../Authentication/auth";


const CancelList = () =>{
    const navigate = useNavigate();

    const [cancels, setCancels] = React.useState([]);

    React.useEffect(() => {
        const accessToken = getAccessToken()
        if (accessToken) {
            fetch(
                `${BACKEND_HOST}/package/user-cancel/`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setCancels(data.cancels);
            })
            .catch((e) => {
                console.log(e);
            });
        }
        else {
            navigate(`/`);
        }
    }, []);

    return(
        <Card>
            <Row className="justify-content-center">
            <Col xxl={12} xl={12} md={12} sm={12}>
                <div className="table-responsive">
                    <Table className="table-borderless text-center mb-0">
                        <thead className="table-light">
                            <tr>
                                <th scope="col">상품명</th>
                                <th scope="col">예약일</th>
                                <th scope="col">자리</th>
                                <th scope="col">취소일</th>
                                <th scope="col">취소금액</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(cancels || []).map((cancel, key) => (
                                <tr key={key} >
                                    <td>{cancel.name}</td>
                                    <td>{cancel.reservationDate}</td>
                                    <td>{cancel.spot}</td>
                                    <td>{cancel.cancelDate}</td>
                                    <td>{cancel.cancelAmount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {cancels.length===0? <div className="d-flex justify-content-center" style={{alignItems: 'center', height: 50}}>취소내역 없음</div>:<></>}
                </div>
            </Col>
            </Row>
            
        </Card>
    )
}
export default CancelList