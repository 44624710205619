import React, { useEffect } from "react";
import { Col, Container, Row, UncontrolledCarousel, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { SITE_ID } from "../../../assets/meta/meta";
import { SloganModal, CarouselModal } from "../../../Components/Common/ManagerModal";
// import ManagerModal from './ManagerModal';
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { BACKEND_HOST } from "../../../api";
import AppDownload from "../../AppDownload";

const Home = (props) => {
  const navigate = useNavigate();

  const [site, setSite] = React.useState({});
  const [sloganText, setSloganText] = React.useState("");
  const [slogan, setSlogan] = React.useState(true);

  const [carousel, setCarousel] = React.useState([]);
  const [carouselModalNumber, setCarouselModalNumber] = React.useState(0);
  const [targetCarousel, setTargetCarousel] = React.useState(-1);

  const [sloganEditModal, setSloganEditModal] = React.useState(false);
  const [carouselEditModal, setCarouselEditModal] = React.useState(false);

  React.useEffect(() => {
    fetch(`${BACKEND_HOST}/sites/camps/${SITE_ID}/`)
      .then((response) => response.json())
      .then((data) => {
        setSite(data);
        /* 사이트 슬로건 받기 */
        setSloganText(data.slogan);
      })
      .catch((e) => console.log(e));

    fetch(`${BACKEND_HOST}/sites/carousel/?site=${SITE_ID}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("carousel", data);
        setCarousel(data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <React.Fragment>
      <section className="mt-4" id="hero">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} sm={10}>
              {!!props.permission && props.permission.permission > 0 ? (
                // <div style={{position: "absolute"}}>
                <Button className="btn btn-soft-secondary mb-3 position-absolute start-0" onClick={() => setSloganEditModal(!sloganEditModal)} style={{ zIndex: "5" }}>
                  슬로건 추가 및 수정하기
                </Button>
              ) : // </div>
              null}
              {sloganText && sloganText.length > 0 ? (
                <div className="text-center">
                  {sloganText.split(";").map((line, idx) => (
                    <h2 key={idx} className="display-6 fw-semibold mb-5 2h-base mt-4" style={{ fontFamily: "Jalnan", color: "#ff5a5f" }}>
                      {line}
                    </h2>
                  ))}
                </div>
              ) : null}
              {!!props.permission && props.permission.permission > 0 ? (
                /* carouselQuantity state 초기값은 1로 설정되어 있음  */
                <>
                  {carousel.map((item, idx) => {
                    return (
                      <div key={idx} className=" demo-carousel mb-2">
                        <Button
                          className="btn btn-soft-secondary mb-3 position-absolute top-50 start-0"
                          style={{ zIndex: "2" }}
                          onClick={() => {
                            setCarouselEditModal(!carouselEditModal);
                            setCarouselModalNumber(idx);
                            setTargetCarousel(item.id);
                          }}
                        >
                          {idx + 1}번 이미지 그룹 수정하기
                        </Button>

                        {carousel.length !== 0 ? (
                          /* 캐롯셀 이미지전환시 전체레이아웃을 크기 변화를 막고자 정적으로 크기를 줬음 */
                          // <div style={{height:"500px"}}>
                          <UncontrolledCarousel interval={4000} items={item.images} />
                        ) : // </div>
                        null}
                      </div>
                    );
                  })}
                  <Button
                    style={{ width: "100%" }}
                    className="btn btn-soft-secondary mt-3"
                    onClick={() => {
                      setCarouselEditModal(!carouselEditModal);
                      setCarouselModalNumber(-1);
                    }}
                  >
                    이미지 그룹 추가하기
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    className="btn btn-soft-secondary mt-3"
                    onClick={() => {
                      setCarouselEditModal(!carouselEditModal);
                      setCarouselModalNumber(-2);
                    }}
                  >
                    이미지 그룹 순서 수정하기
                  </Button>
                </>
              ) : (
                <>
                  {carousel.map((item, idx) => {
                    return (
                      <div key={idx} className=" demo-carousel mb-2">
                        {carousel.length !== 0 ? <UncontrolledCarousel interval={4000} items={item.images} /> : null}
                      </div>
                    );
                  })}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Updating Slogan Modal */}
      <SloganModal setVisible={setSloganEditModal} visible={sloganEditModal} origin={sloganText} slogan={slogan} setSlogan={setSlogan} site={site.id} navigate={navigate} />

      {/* Updating Carousel Modal */}
      <CarouselModal
        modalNumber={carouselModalNumber}
        carouselId={targetCarousel}
        setVisible={setCarouselEditModal}
        visible={carouselEditModal}
        site={site.id}
        navigate={navigate}
        carouselData={carousel}
      />
    </React.Fragment>
  );
};

export default Home;
