import { create } from 'zustand'

export const useUserStore = create((set) => ({
    user: null,
    permission: null,
    isWebPurchase: false,
    isWebPurchaseTicket: false,
    setUser: (user) => set({ user }),
    logout: () => set({ user: null }),
    setPermission: (permission) => set({ permission }),
    setIsWebPurchase: (isWebPurchase) => set({ isWebPurchase }),
    setIsWebPurchaseTicket: (isWebPurchaseTicket) => set({ isWebPurchaseTicket }),
}))

// user: {
//     "id": ,
//     "phone": "",
//     "username": "",
//     "membership": "일반",
//     "thumbnail_img": "",
//     "profile_img": ",
//     "permission": "관리자"
// }

// permission: {
// permission: 2, 
// subdomain: 'test'
// }