import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Collapse, Container, NavbarToggler, NavLink } from "reactstrap";
import Scrollspy from "react-scrollspy";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

// Import Images
// import logodark from "../../../assets/images/logo-dark.png";
// import logolight from "../../../assets/images/logo-light.png";

const UnderNav = styled.div``;

const UnderNavbar = (props) => {
  const navigate = useNavigate();

  // const [userInfo, setUserInfo] = React.useState({
  //     "phone_num": null
  // });

  const [siteName, setSiteName] = useState("");
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");
  const [activeNavBar, setActiveNavBar] = React.useState(props.activeBar);

  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("is-sticky");
    } else {
      setnavClass("");
    }
  }

  return (
    <React.Fragment>
      <div className="under-nav-bar bg-white mt-5">
        <nav className={"navbar navbar-expand-lg border-bottom" + navClass} id="navbar-under">
          <NavbarToggler
            className=" navbar-toggler w-100 fs-2 text-body border-0"
            type="button"
            data-bs-toggle="div"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* scrollspy 더이상 쓰이지 않는 이슈 있음 */}
            <Scrollspy currentClassName="active" className="pt-2 navbar-nav mx-auto d-flex flex-row justify-content-around" id="navbar-example">
              {props.navTabName.map((tab, idx) => {
                return (
                  <div key={idx} style={tab.visible ? {} : { display: "none" }}>
                    {activeNavBar === idx ? (
                      // <div className="nav-item" >
                      //     {tab.url !== "#" ?
                      //         <NavLink className="fs-15 border-bottom border-danger" href={tab.url}>{tab.name}</NavLink>
                      //         : <NavLink className="fs-15 border-bottom border-danger" onClick={() => { alert(`${tab.canAccess ? "준비중입니다." : "로그인 후 이용가능합니다."}`); navigate('/login') }}>{tab.name}</NavLink>}
                      // </div>
                      <Link className="fs-15 text-dark border-bottom border-danger" to={tab.url} style={{ fontFamily: "Gmarket-M" }}>
                        {tab.name}
                      </Link>
                    ) : (
                      // <div className="nav-item">
                      //     {tab.url !== "#" ?
                      //         <NavLink className="fs-15 border-danger" href={tab.url}>{tab.name}</NavLink>
                      //         : <NavLink className="fs-15 border-danger" onClick={() => { alert(`${tab.canAccess ? "준비중입니다." : "로그인 후 이용가능합니다."}`); navigate('/login') }}>{tab.name}</NavLink>}
                      // </div>
                      <Link className="fs-15 text-dark border-danger" to={tab.url} style={{ fontFamily: "Gmarket-M" }}>
                        {tab.name}
                      </Link>
                    )}
                  </div>
                );
              })}
            </Scrollspy>
          </NavbarToggler>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default UnderNavbar;
