import React from "react";
import Navbar from "../../Components/Common/navbar";
import CardSection from "./CardSection";

const Ticket = () => {
    return (
        <div className="layout-wrapper landing overflow-auto" style={{height: "100vh"}}>
            <Navbar activeBar={2}/>
            <CardSection/> 
        </div>
    );
}

export default Ticket;