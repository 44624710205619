import React, { useState } from "react";
import Navbar from "../../Components/Common/navbar";
import { Container, Col, Row, Card, CardBody, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import { TICKET_CATEGORY } from "../../assets/meta/meta";
import { BACKEND_HOST } from "../../api";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";

const ShopList = () => {
  const navigate = useNavigate();

  const [products, setProducts] = React.useState([]);

  const [productQuantity, setProductQuantity] = useState([]);

  React.useEffect(() => {
    fetch(`${BACKEND_HOST}/shop/product/`)
      .then((response) => response.json())
      .then((data) => {
        console.log("get shop products", data);
        setProducts(data);
        setProductQuantity(
          data.map((item, idx) => {
            return { id: item.id, quantity: 0 };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const product_purchase_count = productQuantity
    .map((item) => {
      return item.quantity;
    })
    .reduce((prev, curr, obj) => {
      return prev + curr;
    }, 0);

  return (
    <div className="layout-wrapper landing overflow-auto" style={{ height: "100vh" }}>
      <Navbar activeBar={3} />

      <Container>
        <Row className="mt-4 d-flex flex-wrap" style={{ marginBottom: "100px" }}>
          {products.map((item, idx) => {
            return (
              <Col key={idx} xxl={3} xl={3} md={4} sm={6} xs={6}>
                <Card className="">
                  <div
                    onClick={() => {
                      navigate(`/shop/${item.id}`, { state: { product: { ...item, quantity: productQuantity[idx].quantity } } });
                    }}
                  >
                    <img className="card-img-top img-fluid" src={`/${item.thumbnail}`} alt="Card cap" style={{ height: "200px", objectFit: "contain" }} />
                  </div>
                  <CardBody className="d-flex flex-column justify-content-center align-items-center">
                    <div style={{ fontFamily: "Gmarket-M" }}>{item.name}</div>
                    {item.remaining_stock != 9999 && item.remaining_stock > 0 ? <div style={{ fontFamily: "Gmarket-M", fontSize: "13px" }}>(남은 재고 {item.remaining_stock}개)</div> : null}
                    <div className="card-text mt-2 mb-3" style={{ fontFamily: "Gmarket-M" }}>
                      {new Intl.NumberFormat().format(item.price)} 원
                    </div>
                    <QuantityButtonInput
                      quantity={productQuantity[idx].quantity}
                      onChange={(value) => {
                        if (value > item.remaining_stock) {
                          alert("구매 가능 개수를 초과하였습니다.");
                          return;
                        }
                        const _productQuantity = [...productQuantity];
                        _productQuantity[idx].quantity = value;
                        setProductQuantity(_productQuantity);
                      }}
                    />
                    {/* <div> 상태: {item.state}</div> */}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row
          className="position-fixed d-flex flex-row justify-content-center"
          style={{ bottom: "20px", left: "calc(50vw + 6px)", width: "calc(100vw - 40px)", transform: "translate(-50%)", zIndex: 999 }}
        >
          <Button
            disabled={!product_purchase_count}
            style={{ border: "none", color: "white", background: "#F6475F", width: "100%", borderRadius: "12px", fontSize: "16px", padding: "12px", fontWeight: "bold" }}
            onClick={() => {
              navigate(`/order-shop`, {
                state: {
                  products: products.map((item, idx) => ({ ...item, quantity: productQuantity[idx].quantity })).filter((item) => !!item.quantity),
                },
              });
            }}
          >
            총 {product_purchase_count}개 결제하기
          </Button>
        </Row>
      </Container>
    </div>
  );
};

export default ShopList;
