import moment from 'moment'
import React from 'react'
import { BACKEND_HOST } from '../../../api'
import { getUserId } from '../../Authentication/auth'

function SubCommentBox({
    id, user_id, username, content, profile_img, images, written_date, onDelete
}) {
    const self_user_id = getUserId()
    return (
        <div key={id} className="d-flex flex-row justify-content-start align-content-start gap-3 m-2" style={{paddingLeft: "28px"}}>
            {/* <a href="#" className="pull-left"> */}
            <div><img src={profile_img || `${BACKEND_HOST}/media/ProfileBlank.png`} alt="" className="rounded-circle" style={{ width: "38px", height: "38px", objectFit: "cover" }} /></div>
            <div className="media-body">
                <span className=""><strong className="text-primary">{username}</strong></span>
                <span className="text-muted mx-2">
                    <small className="text-muted" style={{fontSize: "11px"}}>{moment(written_date).format('YYYY-MM-DD HH:mm')}</small>
                </span>
                {/* <span><span className='btn btn-link' style={{boxShadow: "none"}} onClick={() => onReply({
            id, username, content
        })} >reply</span></span> */}
                    {String(self_user_id) === String(user_id) ? <span><span className='btn btn-link' style={{ boxShadow: "none" }} onClick={() => {
                        if(window.confirm("정말로 댓글을 삭제하시겠습니까?")) onDelete(id)
                    }} >삭제</span></span> : null}
                <p>
                    {content}
                </p>
            </div>

        </div>
    )
}

export default SubCommentBox