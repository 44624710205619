import React from "react";

import Navbar from "../../../Components/Common/navbar";
import InquiryListSection from "./InquiryListSection";

const InquiryList = () => {

    return (
        <div className="layout-wrapper landing" style={{minHeight: "100vh"}}>
            <Navbar activeBar={5}/>
            <InquiryListSection />
        </div>
    );
}

export default InquiryList;