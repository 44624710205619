import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Tooltip,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    UncontrolledCarousel,
    InputGroup,
    InputGroupText,
    CardHeader,
    Form,
} from "reactstrap";
import classnames from "classnames";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import tossPayments from "../../../common/payments/toss/api";
import Navbar from '../../../Components/Common/navbar';
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";
import qs from "qs";

//https://#{subdomain}.amazingticket.site/add-options-order?id=#{reserv_id}&deposit=true

const AddOptionsOrder = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const { id, deposit } = query;

    const [orderData, setOrderData] = useState({
        reservationName: "",
        spotName: "",
        reservDate: "",
        reservId: "",
        options: [],
    });

    useEffect(() => {
        if (location.state && location.state.reservId) {
            console.log("location.state", location.state)
            setOrderData(location.state);
        }
    }, [location.state]);

    useEffect(() => {
        if (id && deposit) {
            console.log("id", id)
            console.log("deposit", deposit)

            const accessToken = getAccessToken()
            fetch(`${BACKEND_HOST}/package/get-reservation/${id}/`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(`package/reservations/${id}/`, data)
                    if (!data.package_id) {
                        alert("예약 정보 로드 실패..")
                    }
                    const package_id = data.package_id
                    const reservationName = data["name"]
                    const spotName = data["spot"]
                    const reservDate = data["reservationDate"]
                    const reservId = data.id
                    fetch(`${BACKEND_HOST}/package/items/${package_id}/`)
                        .then((response) => response.json())
                        .then((data) => {
                            console.log(`package/items/${package_id}/`, data)
                            const depositOption = data.options.find((option) => option.type === "deposit")
                            setOrderData({
                                reservationName: reservationName,
                                spotName: spotName,
                                reservDate: reservDate,
                                reservId: reservId,
                                options: depositOption ? [{
                                    ...depositOption,
                                    "reservId": reservId, "optionId": depositOption.id,
                                }] : [],
                            })
                        })
                })
                .catch(e => console.log(e));
        }
    }, [id, deposit]);

    window.onscroll = function () {
        scrollFunction();
    };
    const scrollFunction = () => {
        const element = document.getElementById("back-to-top");
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    };
    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const [width, setWidth] = React.useState(null);

    const [user, setUser] = useState({
        phoneNum: null,
    });
    const [name, setName] = useState("")
    const [cancelRuleAgree, setCancelRuleAgree] = React.useState(false);
    const [purchaseAllAgree, setPurchaseAllAgree] = React.useState(false);
    const [purchaseOneAgree, setPurchaseOneAgree] = React.useState(false);

    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);

    const toggleAllAgree = (agree) => {
        setPurchaseAllAgree(agree);
        setPurchaseOneAgree(agree);
    }
    const togglePurchaseAgreeOptions = (agree) => {
        // 임시 코드 => 하위 동의항목이 더 생기면 바꿔야함.
        setPurchaseAllAgree(agree);
        setPurchaseOneAgree(agree);
    }

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    const totalOrderPrice = orderData.options
        .map((item) => { return parseInt(item.price) })
        .reduce((sum, currValue) => { return sum + currValue }, 0);

    const processPayment = () => {
        const _body = {
            options: orderData.options.map((item) => { return { "reserv_id": item.reservId, "option_id": item.optionId } })
        }
        console.log(_body)

        const accessToken = getAccessToken()
        if (accessToken) {
            fetch(
                `${BACKEND_HOST}/package/pre-order-option/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(_body)
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);

                    // 결제창 닫았을 때 catch error하기
                    tossPayments.requestPayment('카드', {
                        amount: totalOrderPrice,
                        orderId: data.orderId,
                        orderName: `${orderData.reservationName}_추가옵션`,
                        customerName: name,
                        successUrl: `${BACKEND_HOST}/package/order-option/`,
                        failUrl: `https://${SUBDOMAIN}.amazingticket.site/my-page`,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    alert("추가옵션을 저장하는데 실패했습니다.");
                    navigate(`/my-page`);
                });
        }
        else {
            navigate(`/`);
        }
    }

    const logOut = () => {
        alert("허용되지 않은 접근입니다! 다시 로그인 해주세요.")
    }

    React.useEffect(() => {
        const accessToken = getAccessToken()
        if (accessToken) {
            let decoded = {}
            try {
                decoded = jwtDecode(accessToken);
            } catch (e) {
                console.log(e);
                logOut();
            }

            const userId = decoded["user_id"]
            fetch(
                `${BACKEND_HOST}/accounts/users/${userId}/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (!data["전화번호"]) {
                        logOut();
                    }
                    else {
                        setUser({ "phoneNum": data["전화번호"], "name": data["이름"] });
                        setName(data["이름"]);
                    }
                })
                .catch((e) => {
                    logOut();
                });
        }
        else {
            setUser({
                "phoneNum": null
            });
        }

        // console.log("orderData.options", orderData.options)
    }, [location]);

    React.useEffect(() => {
        setWidth(window.innerWidth);
    }, [])


    if (orderData.options.length === 0) {
        return (
            <React.Fragment>
                <div className="layout-wrapper landing" style={{ minHeight: "100vh" }}>
                    <Navbar />
                    <Container fluid>
                        <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className="text-center">
                                로딩중입니다...
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }


    return (

        <React.Fragment>
            <div className="layout-wrapper landing" style={{ minHeight: "100vh" }}>
                <Navbar />



                <Container fluid>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody className="checkout-tab">
                                    <Form action="#">
                                        <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                            <Nav
                                                className="nav-pills nav-justified custom-nav"
                                                role="tablist"
                                            >
                                                <NavItem role="presentation">
                                                    <NavLink href="#"
                                                        className={classnames({ active: activeTab === 1, done: (activeTab <= 4 && activeTab >= 0) }, "fs-15 p-3")}
                                                        onClick={() => { }}
                                                    >
                                                        <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                        {
                                                            (width < 400) ?
                                                                null :
                                                                "회원정보"
                                                        }
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem role="presentation">
                                                    <NavLink href="#"
                                                        className={classnames({ active: activeTab === 2, done: activeTab <= 4 && activeTab > 1 }, "fs-15 p-3")}
                                                        onClick={() => { }}
                                                    >
                                                        <i className="ri-truck-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                        {
                                                            (width < 400) ?
                                                                null :
                                                                "상품정보"
                                                        }
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem role="presentation">
                                                    <NavLink href="#"
                                                        className={classnames({ active: activeTab === 3, done: activeTab <= 4 && activeTab > 2 }, "fs-15 p-3")}
                                                        onClick={() => { }}
                                                    >
                                                        <i className="ri-bank-card-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                        {
                                                            (width < 400) ?
                                                                null :
                                                                "결제하기"
                                                        }
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={1} id="pills-bill-info">
                                                <div>
                                                    <h5 className="mb-1">구매정보</h5>
                                                    <p className="text-danger mb-4">
                                                        {
                                                            (name || user.phoneNum) ?
                                                                "구매자 성함을 입력해주세요." :
                                                                "로그인을 해주세요."
                                                        }
                                                    </p>
                                                </div>

                                                <div>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <div className="mb-3">
                                                                <Label
                                                                    htmlFor="billinginfo-firstName"
                                                                    className="form-label"
                                                                >
                                                                    구매자
                                                                </Label>
                                                                <Input
                                                                    value={name}
                                                                    onChange={(e) => setName(e.target.value)}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="billinginfo-firstName"
                                                                    placeholder="구매자명"
                                                                    disabled={!(user.phoneNum)}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col sm={6}>
                                                            <div className="mb-3">
                                                                <Label
                                                                    htmlFor="billinginfo-phone"
                                                                    className="form-label"
                                                                >
                                                                    휴대폰번호
                                                                </Label>
                                                                <Input
                                                                    value={(user.phoneNum) ?? ""}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="billinginfo-phone"
                                                                    placeholder="휴대폰번호"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <div className="d-flex align-items-start gap-3 mt-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-label right ms-auto nexttab"
                                                            onClick={() => {
                                                                if (!(name && user.phoneNum)) {
                                                                    alert("(필수)구매자 성함을 입력해주세요.");
                                                                    return
                                                                }
                                                                toggleTab(activeTab + 1);
                                                                if (name !== user.name) {
                                                                    fetch(
                                                                        `${BACKEND_HOST}/accounts/set-username/`, {
                                                                        method: "POST",
                                                                        headers: {
                                                                            "Content-Type": "application/json",
                                                                            Authorization: `Bearer ${getAccessToken()}`
                                                                        },
                                                                        body: JSON.stringify({ 'name': name })
                                                                    }
                                                                    )
                                                                        .then((response) => response.json())
                                                                        .catch((e) => console.log(e));
                                                                }
                                                            }}
                                                        >
                                                            <i className="ri-truck-line label-icon align-middle bg-danger"></i>
                                                            다음
                                                        </button>
                                                    </div>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={2}>
                                                <div>
                                                    <h5 className="mb-1">상품정보</h5>
                                                    <p className="text-muted mb-4">
                                                        구매하실 상품을 확인해주세요.
                                                    </p>
                                                </div>

                                                <div className="mt-4">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <div className="flex-grow-1">
                                                            <h5 className="fs-14 mb-0">상세정보</h5>
                                                        </div>
                                                    </div>
                                                    <Row className="gy-3">
                                                        <Col lg={6} sm={6}>
                                                            <div className="form-check card-radio">
                                                                <Input
                                                                    id="detailInfo01"
                                                                    name="detailInfo"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    defaultChecked
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    htmlFor="detailInfo01"
                                                                >
                                                                    <span className="mb-1 fw-semibold d-block text-muted text-uppercase">
                                                                        {orderData.reservationName} 추가 옵션
                                                                    </span>

                                                                    <span className="mb-1 fw-semibold d-block text-muted text-uppercase">
                                                                        날짜: 20{orderData.reservDate}
                                                                    </span>

                                                                    <span className="mb-1 fw-semibold d-block text-muted text-uppercase">
                                                                        자리: {orderData.spotName}
                                                                    </span>

                                                                    <span className="text-muted fw-normal text-wrap mb-4 d-block">
                                                                        {orderData.options.map((option, oidx, oobj) => {
                                                                            return <span className="text-muted fw-normal d-block" key={oidx}>
                                                                                {option.name} - {option.price}
                                                                                {(oidx !== oobj.length - 1) ? ", " : ""}
                                                                            </span>
                                                                        })}
                                                                    </span>

                                                                    <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                                                        {
                                                                            `총 가격 - ${totalOrderPrice}원`
                                                                        }
                                                                    </span>
                                                                </Label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {deposit ? <div className="align-items-start gap-3 mt-4">

                                                        <p style={{ textAnchor: "middle" }}>
                                                            <i className="ri ri-information-line label-icon align-middle fs-20"></i>&nbsp;
                                                            청소보증금이란?</p>
                                                        <p style={{}}>청결한 시설 관리 및 비품 관리를 위해 패키지 상품 구매 시 청소보증금이 결제됩니다.<br />
                                                            시설 이용 후 정리를 완료하고 체크아웃 버튼을 누르면 관리자가 정리 사항을 확인합니다.<br />
                                                            <span style={{ color: "red" }}>체크아웃 요청이 승인 되면 자동 환불됩니다.</span></p>
                                                    </div> : null}
                                                </div>

                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2 bg-danger"></i>
                                                        회원정보
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-label right ms-auto nexttab"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                        }}
                                                    >
                                                        <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2 bg-danger"></i>
                                                        결제하기
                                                    </button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={3}>
                                                <div>
                                                    <h5 className="mb-1">결제하기</h5>
                                                    <p className="text-muted mb-4">
                                                        구매자 정보와 상품 정보를 확인해주세요.
                                                    </p>
                                                </div>

                                                <div
                                                    className="collapse show"
                                                    id="paymentmethodCollapse"
                                                >
                                                    <Card className="p-4 border shadow-none mb-0 mt-4">
                                                        <Row className="gy-3">
                                                            <Col sm={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="billinginfo-firstName"
                                                                        className="form-label"
                                                                    >
                                                                        구매자
                                                                    </Label>
                                                                    <Input
                                                                        value={name}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="billinginfo-firstName"
                                                                        placeholder="구매자명"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col sm={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="billinginfo-phone"
                                                                        className="form-label"
                                                                    >
                                                                        휴대폰번호
                                                                    </Label>
                                                                    <Input
                                                                        value={(user.phoneNum) ?? ""}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="billinginfo-phone"
                                                                        placeholder="휴대폰번호"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="gy-3">
                                                            <Col lg={6} sm={6}>
                                                                <div className="form-check card-radio">
                                                                    <Input
                                                                        id="orderInfo01"
                                                                        name="orderInfo"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        defaultChecked
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        htmlFor="orderInfo01"
                                                                    >
                                                                        <span className="mb-4 fw-semibold d-block text-muted text-uppercase">
                                                                            {orderData.reservationName} 추가 옵션
                                                                        </span>


                                                                        <span className="mb-4 fw-semibold d-block text-muted text-uppercase">
                                                                            자리: {orderData.spotName}
                                                                        </span>

                                                                        <span className="text-muted fw-normal text-wrap mb-4 d-block">
                                                                            {orderData.options.map((option, oidx, oobj) => {
                                                                                return <span className="text-muted fw-normal d-block" key={oidx}>
                                                                                    {option.name} - {option.price}
                                                                                    {(oidx !== oobj.length - 1) ? ", " : ""}
                                                                                </span>
                                                                            })}
                                                                        </span>

                                                                        <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                                                            {
                                                                                `총 가격 - ${totalOrderPrice}원`
                                                                            }
                                                                        </span>
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card>

                                                    <Card className="p-4 border shadow-none mb-0 mt-4">
                                                        <CardHeader className="border-0">
                                                            <Row className="g-4 align-items-center">
                                                                <div className="col-sm">
                                                                    <div>
                                                                        <h6 className="mb-3 ff-secondary fw-semibold lh-base">취소/환불 규정에 대한 동의</h6>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </CardHeader>
                                                        <Row className="gy-3">
                                                            <Col sm={6}>
                                                                {deposit ? <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                                                    청소보증금은 체크아웃 요청이 관리자에 의해 승인 되면 자동 환불
                                                                </span> :
                                                                    <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                                                        사용기간 내의 미사용 100% 환불<br />
                                                                        (유효기간 이후 환불불가)
                                                                    </span>}
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <hr />
                                                        <Row className="gy-3">
                                                            <Col sm={12}>
                                                                <div className="form-check">
                                                                    <Input className="form-check-input" type="checkbox" id="cancelRuleAgree" checked={cancelRuleAgree} onChange={(e) => { setCancelRuleAgree(e.target.checked); }} />
                                                                    <label className="form-check-label" htmlFor="cancelRuleAgree">
                                                                        동의하기
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card>

                                                    <Card className="p-4 border shadow-none mb-0 mt-4">
                                                        <CardHeader className="border-0">
                                                            <Row className="g-4 align-items-center">
                                                                <div className="col-sm">
                                                                    <div>
                                                                        <h6 className="mb-3 ff-secondary fw-semibold lh-base">결제동의</h6>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </CardHeader>
                                                        <Row className="gy-3">
                                                            <Col sm={12}>
                                                                <div className="form-check">
                                                                    <Input className="form-check-input" type="checkbox" id="purchaseAllAgree" checked={purchaseAllAgree} onChange={(e) => { toggleAllAgree(e.target.checked); }} />
                                                                    <label className="form-check-label" htmlFor="purchaseAllAgree">
                                                                        전체 동의하기
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <hr />
                                                        <Row className="gy-3">
                                                            <Col sm={12}>
                                                                <div className="form-check">
                                                                    <Input className="form-check-input" type="checkbox" id="purchaseOneAgree" checked={purchaseOneAgree} onChange={(e) => { togglePurchaseAgreeOptions(e.target.checked); }} />
                                                                    <label className="form-check-label" htmlFor="purchaseOneAgree">
                                                                        (필수)구매조건 확인 및 결제진행에 동의
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card>

                                                </div>

                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2 bg-danger"></i>
                                                        상품정보
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-label right ms-auto nexttab"
                                                        onClick={() => { processPayment() }}
                                                        disabled={!cancelRuleAgree || !purchaseAllAgree}
                                                    >
                                                        <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2 bg-danger"></i>
                                                        결제하기
                                                    </button>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>



                <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
                    <i className="ri-arrow-up-line"></i>
                </button>
            </div>
        </React.Fragment>
    )
}

export default AddOptionsOrder