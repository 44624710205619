import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BACKEND_HOST } from '../../../api'
import { getAccessToken, getUserId } from '../../Authentication/auth'

function CommentInput({ parentComment, article_id, needRefresh, cancelSubCommentReply }) {
    const navigate = useNavigate()
    const [content, setContent] = useState("")

    const checkData = () => {
        if (!content) {
            return null;
        }
        // const userId = getUserId()
        // if (userId === null) navigate(`/`);
        const data = {
            // "user": userId,
            "article": article_id,
            "content": content,
        }
        if (parentComment) data["parent"] = parentComment.id
        return data
    }

    const handlePost = useCallback(() => {
        const data = checkData()
        console.log("sendData", data)
        if (data === null) {
            // alert("내용을 입력해주세요.");
            return;
        }
        else {
            const accessToken = getAccessToken()
            if (accessToken) {
                fetch(`${BACKEND_HOST}/inquiry/comment/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log("POST inquiry/comments", data)
                        alert("등록 완료");
                        setContent("")
                        // navigate(`/inquiry`)
                        needRefresh()
                    })
                    .catch((e) => console.log(e));
            }
        }
    }, [parentComment, checkData])

    return (
        <div className='mt-2  mb-4' style={parentComment ? { backgroundColor: "lightgoldenrodyellow", borderRadius: "4px", padding: "4px" }:null}>
            {parentComment ? <div style={{ width: "100%", padding: "2px 8px" }} 
            onClick={() => {cancelSubCommentReply()}}>
                to {parentComment.username}
                <span style={{marginLeft: "18px", padding: "0 4px", background: "lightgray", borderRadius: "4px"}}>x</span>
                </div> : null}
            <div className='d-flex flex-row justify-content-between'>
                <textarea style={{ width: `calc(100% - 80px` }} className="form-control" placeholder="write a comment..." rows="2"
                    value={content} onChange={(e) => setContent(e.target.value)}></textarea>
                <button style={{ width: "74px" }} type="button" className="btn btn-info pull-right"
                    onClick={() => handlePost()}>등록</button>
            </div>
        </div>

    )
}

export default CommentInput