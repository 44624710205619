import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useRoutes } from "react-router-dom";
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";
import moment from "moment";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { useUserStore } from "../../../store/store";
import useElementSize from "../../../hooks/useElementSize";
// import FeatherIcon from 'feather-icons-react';
import { RxLockClosed } from "react-icons/rx";
import { FiAlertCircle, FiBell } from "react-icons/fi";
import { RiQuestionLine } from "react-icons/ri";

const PrevInquiryListSection = (props) => {
  const [permission, user] = useUserStore((state) => [state.permission, state.user]);

  const { targetUser } = props;

  // get url params
  const params = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(params.get("page") || 0);

  const [{ width: tableElementWidth }, tableRef] = useElementSize();
  const tableWidth = (() => {
    // console.log("tableElementWidth", tableElementWidth);
    if (window.innerWidth >= 1200) {
      // console.log("1200");
      return 1200 - 40;
    } else if (window.innerWidth >= 992) {
      // console.log("992");
      return 992 - 20;
    } else if (window.innerWidth >= 768) {
      // console.log("768");
      return 768 - 40;
    } else if (window.innerWidth >= 576) {
      // console.log("576");
      return 576 - 80;
    }
    return tableElementWidth - 20;
  })();

  const [allNotices, setAllNotices] = useState([]);
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(null);

  const columns = [
    {
      Header: "제목",
      accessor: "title",
      width: tableWidth > 576 ? tableWidth - 260 : tableWidth - 120, // - 100 - 50 - 120
      Cell: (cellProps) => {
        return (
          <Link to={`/inquiry/detail/${cellProps.row.original.id}?page=${page}`}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div style={{ maxWidth: tableWidth > 576 ? tableWidth - 260 : tableWidth - 120, overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {!!permission?.permission && cellProps.row.original["need_extra_info"] ? <FiAlertCircle size="20" style={{ marginTop: "-4px", color: "#f06548" }} /> : null}

                {(!cellProps.row.original["comment_count"] && moment(cellProps.row.original["written_date"]) > moment().subtract(1, "days")) ||
                (!!cellProps.row.original["latest_comment_date"] && moment(cellProps.row.original["latest_comment_date"]) > moment().subtract(1, "days")) ? (
                  <i className="mdi mdi-new-box fs-22"></i>
                ) : null}

                {cellProps.row.original["is_notice"] ? "[공지] " : ""}

                {!!permission?.permission && !cellProps.row.original["is_notice"] && !cellProps.row.original["is_latest_comment_by_staff"] ? (
                  <FiBell size="20" style={{ marginTop: "-4px", color: "#f06548" }} />
                ) : null}

                {["2", "1"].includes(cellProps.row.original["mode"]) ? <RxLockClosed size="20" style={{ marginTop: "-4px" }} /> : null}

                <span>&nbsp;</span>
                {cellProps.row.original["title"]}
              </div>

              {cellProps.row.original["comment_count"] > 0 ? (
                <>
                  <span>&nbsp;</span>
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      width: "20px",
                      height: "20px",
                      borderRadius: "100px",
                      fontSize: "12px",
                      backgroundColor: "#F6475F",
                      color: "white",
                    }}
                  >
                    <div style={{ width: "20px", height: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>{cellProps.row.original["comment_count"]}</div>
                  </div>
                </>
              ) : null}
            </div>
          </Link>
        );
      },
    },
    tableWidth > 576
      ? {
          Header: "작성일",
          accessor: "written_date",
          width: 100,
          Cell: (cellProps) => {
            return moment(cellProps.row.original["written_date"]).format("YYYY-MM-DD");
          },
        }
      : {
          Header: "",
          accessor: "written_date",
          width: 0,
          Cell: (cellProps) => {
            return <span style={{ display: "none" }}>{moment(cellProps.row.original["written_date"]).format("YYYY-MM-DD")}</span>;
          },
        },
    {
      Header: "",
      accessor: "phone_num",
      width: 0,
      Cell: (cellProps) => {
        return <span style={{ display: "none" }}>{cellProps.row.original["phone_num"]}</span>;
      },
    },
    {
      Header: "작성자",
      accessor: "user_full_name",
      accessorFn: (cellProps) => {
        return cellProps.row.original["user_full_name"];
      },
      width: 50,
      Cell: (cellProps) => {
        if (!!cellProps.row.original["user_permission"]?.permission) {
          return <span>관리자</span>;
        }
        let _username = cellProps.row.original["user_full_name"];
        _username = user && cellProps.row.original["user_id"] === String(user.id) ? _username : _username[0] + "*".repeat(_username.length - 1);
        return <span>{_username}</span>;
      },
    },
  ];

  useEffect(() => {
    if (!targetUser) return;

    setLoading(true);
    const accessToken = getAccessToken();
    const _subdomain = SUBDOMAIN;
    // const _subdomain = (SUBDOMAIN === "test") ? "" : SUBDOMAIN;
    fetch(`${BACKEND_HOST}/inquiry/articles/?subdomain=${_subdomain}&phone_num=${targetUser}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.length === 0) return;
        // console.log(data)
        // let columnName = Object.keys(data[0]);

        // {
        //     "id": 1,
        //     "title": "텟트",
        //     "content": "글글",
        //     "written_date": "2023-03-08T11:44:12.194227",
        //     "image_group_key": "9d8cd9bf-cda8-4f61-911f-52697497c587",
        //     "address": "부산 금정구 중앙대로1685번길 22",
        //     "site": 9,
        //     "user": {id, username, ...},
        //     "images": [
        //         {id, image, ...}
        //     ]
        // }
        setAllNotices(data);
        setNotices(data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }, [targetUser]);

  return (
    <div style={{ overflowX: "hidden" }}>
      <Row className="justify-content-center">
        <Col>
          <Card id="customerList">
            <div className="card-body pt-0">
              <div>
                {notices.length ? (
                  <TableContainer
                    defaultPage={page}
                    ref={tableRef}
                    columns={columns}
                    data={notices || []}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    customPageSize={10}
                    className="custom-header-css"
                    isCustomerFilter={false}
                    theadClass="table-light text-muted fs-13"
                    onChangePage={(page) => setPage(page)}
                  />
                ) : (
                  <Loader loading={loading} />
                )}
              </div>

              <ToastContainer closeButton={false} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PrevInquiryListSection;
