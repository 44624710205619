import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input, Table } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

import BgFrame from "../../../Components/Common/BgFrame";

import tossPayments from "../../../common/payments/toss/api";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";

const OrderProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [width, setWidth] = React.useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [user, setUser] = useState({
    phoneNum: null,
  });
  const [name, setName] = useState("");
  const [cancelRuleAgree, setCancelRuleAgree] = React.useState(false);
  const [purchaseAllAgree, setPurchaseAllAgree] = React.useState(false);
  const [purchaseOneAgree, setPurchaseOneAgree] = React.useState(false);

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  useEffect(() => {
    console.log("location.state", location.state);
  }, [location]);

  const toggleAllAgree = (agree) => {
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };
  const togglePurchaseAgreeOptions = (agree) => {
    // 임시 코드 => 하위 동의항목이 더 생기면 바꿔야함.
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };

  const optionsPrice = location.state.options
    .map((item) => {
      return parseInt(item.price);
    })
    .reduce((sum, currValue) => {
      return sum + currValue;
    }, 0);
  const totalOrderPrice = location.state.product.price * (location.state.type === 0 ? location.state.spotId.length : location.state.quantity) + optionsPrice;

  const processPayment = () => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      alert("로그인이 필요한 서비스입니다.");
      navigate(`/login`);
      return;
    }

    fetch(`${BACKEND_HOST}/${location.state.type === 0 ? "package" : "ticket"}/pre-order/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(
        location.state.type === 0
          ? {
              spots: location.state.spotId,
              startDate: location.state.selectDate,
              options: location.state.options.map((item) => {
                return { spot_id: item.spotId, option_id: item.optionId };
              }),
            }
          : { ticket: location.state.product.id, quantity: location.state.quantity }
      ),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data >>> ", data);
        if ("error" in data) {
          alert(data.error);
          if (location.state.type === 0) {
            navigate(`/detail/package/${location.state.product.id}`, {
              state: {
                selectedDate: location.state.selectDate,
                selectedSpotId: location.state.spotId,
              },
            });
          } else {
            navigate(`/ticket`);
          }
          //   navigate(`${location.state.type === 0 ? `/reservation` : "/ticket"}`);
          return;
        }
        tossPayments.requestPayment("카드", {
          amount: totalOrderPrice,
          orderId: data.orderId,
          orderName: `${location.state.product.name}`,
          customerName: name,
          successUrl: `${BACKEND_HOST}/${location.state.type === 0 ? "package" : "ticket"}/order/`,
          failUrl: `https://${SUBDOMAIN}.amazingticket.site/${location.state.type === 0 ? "reservation" : "ticket"}`,
        });
      })
      .catch((e) => {
        console.log(e);
        alert("지금은 구매가능 시간이 아니거나 품절된 상품입니다.");
        navigate(`${location.state.type === 0 ? "/reservation" : "/ticket"}`);
      });
  };

  const logOut = () => {
    alert("허용되지 않은 접근입니다! 다시 로그인 해주세요.");
  };

  React.useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      let decoded = {};
      try {
        decoded = jwtDecode(accessToken);
      } catch (e) {
        console.log(e);
        logOut();
      }

      const userId = decoded["user_id"];
      fetch(`${BACKEND_HOST}/accounts/users/${userId}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data["전화번호"]) {
            logOut();
          } else {
            setUser({ phoneNum: data["전화번호"], name: data["이름"] });
            setName(data["이름"]);
          }
        })
        .catch((e) => {
          logOut();
        });
    } else {
      setUser({
        phoneNum: null,
      });
    }

    console.log("location.state.options", location.state.options);
  }, [location]);

  React.useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <BgFrame>
      <React.Fragment>
        <div>
          <Container fluid>
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody className="checkout-tab">
                    <Form action="#">
                      <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                        <Nav className="nav-pills nav-justified custom-nav" role="tablist">
                          <NavItem role="presentation">
                            <NavLink href="#" className={classnames({ active: activeTab === 1, done: activeTab <= 4 && activeTab >= 0 }, "fs-15 p-3")} onClick={() => {}}>
                              <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                              {width < 400 ? null : "회원정보"}
                            </NavLink>
                          </NavItem>
                          <NavItem role="presentation">
                            <NavLink href="#" className={classnames({ active: activeTab === 2, done: activeTab <= 4 && activeTab > 1 }, "fs-15 p-3")} onClick={() => {}}>
                              <i className="ri-truck-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                              {width < 400 ? null : "상품정보"}
                            </NavLink>
                          </NavItem>
                          <NavItem role="presentation">
                            <NavLink href="#" className={classnames({ active: activeTab === 3, done: activeTab <= 4 && activeTab > 2 }, "fs-15 p-3")} onClick={() => {}}>
                              <i className="ri-bank-card-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                              {width < 400 ? null : "결제하기"}
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1} id="pills-bill-info">
                          <div>
                            <h5 className="mb-1">구매정보</h5>
                            <p className="text-danger mb-4">{name || user.phoneNum ? "구매자 성함을 입력해주세요." : "로그인을 해주세요."}</p>
                          </div>

                          <div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label htmlFor="billinginfo-firstName" className="form-label">
                                    구매자
                                  </Label>
                                  <Input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    id="billinginfo-firstName"
                                    placeholder="구매자명"
                                    disabled={!user.phoneNum}
                                  />
                                </div>
                              </Col>

                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label htmlFor="billinginfo-phone" className="form-label">
                                    휴대폰번호
                                  </Label>
                                  <Input value={user.phoneNum ?? ""} type="text" className="form-control" id="billinginfo-phone" placeholder="휴대폰번호" disabled />
                                </div>
                              </Col>
                            </Row>

                            <div className="d-flex align-items-start gap-3 mt-3">
                              <button
                                type="button"
                                className="btn btn-danger btn-label right ms-auto nexttab"
                                onClick={() => {
                                  if (!(name && user.phoneNum)) {
                                    alert("(필수)구매자 성함을 입력해주세요.");
                                    return;
                                  }
                                  toggleTab(activeTab + 1);
                                  if (name !== user.name) {
                                    fetch(`${BACKEND_HOST}/accounts/set-username/`, {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${getAccessToken()}`,
                                      },
                                      body: JSON.stringify({ name: name }),
                                    })
                                      .then((response) => response.json())
                                      .catch((e) => console.log(e));
                                  }
                                }}
                              >
                                <i className="ri-truck-line label-icon align-middle bg-danger"></i>
                                다음
                              </button>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div>
                            <h5 className="mb-1">상품정보</h5>
                            <p className="text-muted mb-4">구매하실 상품을 확인해주세요.</p>
                          </div>

                          <div className="mt-4">
                            <div className="d-flex align-items-center mb-2">
                              <div className="flex-grow-1">
                                <h5 className="fs-14 mb-0">상세정보</h5>
                              </div>
                            </div>
                            <Row className="gy-3">
                              <Col lg={6} sm={6}>
                                <div className="form-check card-radio">
                                  <Input id="detailInfo01" name="detailInfo" type="radio" className="form-check-input" defaultChecked />
                                  <Label className="form-check-label" htmlFor="detailInfo01">
                                    <span className="mb-4 fw-semibold d-block text-muted text-uppercase">{location.state.product.name}</span>
                                    <span className="mb-4 d-block text-muted text-uppercase">{`캠핑장 - ${location.state.product.site}`}</span>

                                    <span className="text-muted fw-normal text-wrap mb-1 d-block">{location.state.selectDate !== null ? `예약일 - ${location.state.selectDate}` : null}</span>

                                    {location.state.type !== 0 ? <span className="text-muted fw-normal text-wrap mb-1 d-block">수량 - {location.state.quantity}</span> : null}

                                    {location.state.spotId !== null ? (
                                      <span className="text-muted fw-normal text-wrap my-3 d-block">
                                        <span className="text-muted fw-normal text-wrap mb-1 d-block">자리:</span>
                                        {location.state.spotId.map((spot, idx, obj) => {
                                          const _options = location.state.options.filter((value) => value.spotId === spot.id);
                                          return (
                                            <span key={idx} className="text-muted fw-normal text-wrap mb-1 d-block mx-1">
                                              {`${spot.name}번 `}
                                              {_options.map((option, oidx, oobj) => {
                                                return (
                                                  <span key={oidx}>
                                                    {oidx === 0 ? "- " : ""}
                                                    {option.name}

                                                    {oidx !== oobj.length - 1 ? ", " : ""}
                                                  </span>
                                                );
                                              })}
                                              {idx !== obj.length - 1 ? ", " : ""}
                                            </span>
                                          );
                                        })}
                                      </span>
                                    ) : null}

                                    <span className="text-muted fw-normal text-wrap mb-1 d-block">{`총 가격 - ${totalOrderPrice}원`}</span>
                                  </Label>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-danger btn-label previestab"
                              onClick={() => {
                                toggleTab(activeTab - 1);
                              }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2 bg-danger"></i>
                              회원정보
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-label right ms-auto nexttab"
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2 bg-danger"></i>
                              결제하기
                            </button>
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            <h5 className="mb-1">결제하기</h5>
                            <p className="text-muted mb-4">구매자 정보와 상품 정보를 확인해주세요.</p>
                          </div>

                          <div className="collapse show" id="paymentmethodCollapse">
                            <Card className="p-4 border shadow-none mb-0 mt-4">
                              <Row className="gy-3">
                                <Col sm={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="billinginfo-firstName" className="form-label">
                                      구매자
                                    </Label>
                                    <Input value={name} type="text" className="form-control" id="billinginfo-firstName" placeholder="구매자명" disabled />
                                  </div>
                                </Col>

                                <Col sm={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="billinginfo-phone" className="form-label">
                                      휴대폰번호
                                    </Label>
                                    <Input value={user.phoneNum ?? ""} type="text" className="form-control" id="billinginfo-phone" placeholder="휴대폰번호" disabled />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="gy-3">
                                <Col lg={6} sm={6}>
                                  <div className="form-check card-radio">
                                    <Input id="orderInfo01" name="orderInfo" type="radio" className="form-check-input" defaultChecked />
                                    <Label className="form-check-label" htmlFor="orderInfo01">
                                      <span className="mb-4 fw-semibold d-block text-muted text-uppercase">{location.state.product.name}</span>
                                      <span className="mb-4 d-block text-muted text-uppercase">{`캠핑장 - ${location.state.product.site}`}</span>

                                      <span className="text-muted fw-normal text-wrap mb-1 d-block">{location.state.selectDate !== null ? `예약일 - ${location.state.selectDate}` : null}</span>

                                      {location.state.type !== 0 ? <span className="text-muted fw-normal text-wrap mb-1 d-block">수량 - {location.state.quantity}</span> : null}

                                      {location.state.spotId !== null ? (
                                        <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                          {/* 자리 -
                                                                                        {
                                                                                            (location.state.spotId).map((spot) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {`${spot.name}번, `}
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        } */}
                                          자리 -
                                          {location.state.spotId.map((spot, idx, obj) => {
                                            const _options = location.state.options.filter((value) => value.spotId === spot.id);
                                            return (
                                              <span key={idx} className="mx-1">
                                                {`${spot.name}번 `}
                                                {_options.map((option, oidx, oobj) => {
                                                  return (
                                                    <span key={oidx}>
                                                      {oidx === 0 ? "- " : ""}
                                                      {option.name}

                                                      {oidx !== oobj.length - 1 ? ", " : ""}
                                                    </span>
                                                  );
                                                })}
                                                {idx !== obj.length - 1 ? ", " : ""}
                                              </span>
                                            );
                                          })}
                                        </span>
                                      ) : null}
                                      <span className="text-muted fw-normal text-wrap mb-1 d-block">{`총 가격 - ${totalOrderPrice}원`}</span>
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                            </Card>

                            <Card className="p-4 border shadow-none mb-0 mt-4">
                              <CardHeader className="border-0">
                                <Row className="g-4 align-items-center">
                                  <div className="col-sm">
                                    <div>
                                      <h6 className="mb-3 ff-secondary fw-semibold lh-base">취소/환불 규정에 대한 동의</h6>
                                    </div>
                                  </div>
                                </Row>
                              </CardHeader>
                              <Row className="gy-3">
                                <Col sm={6}>
                                  {location.state.type !== 0 ? (
                                    <label className="form-check-label" htmlFor="-">
                                      사용기간 내의 미사용 100% 환불
                                      <br />
                                      (유효기간 이후 환불불가)
                                      {location.state.product.bundle_count && location.state.product.bundle_count > 1 ? (
                                        <div>
                                          - 묶음상품은 전체취소(=전체환불) 가능합니다. 다만, 묶음 중 1장이라도 사용, 선물 시 환불 불가합니다.
                                          <br />
                                          - 부분취소 불가합니다.
                                          <br />
                                          - 유효기간은 구입일로 부터 1년입니다.
                                          <br />
                                          - 유효기간 만료 전까지 사용 또는 선물(양도) 해주세요.
                                          <br />
                                        </div>
                                      ) : null}
                                    </label>
                                  ) : (
                                    <div className="table-responsive">
                                      <Table className="table-bordered border-dark table-nowrap align-middle mb-0">
                                        <tbody>
                                          <tr>
                                            <td className="fw-medium">이용 3일 전까지</td>
                                            <td>취소수수료없음</td>
                                          </tr>
                                          <tr>
                                            <td className="fw-medium">이용 2일 전</td>
                                            <td>총결제금액의 30% 차감</td>
                                          </tr>
                                          <tr>
                                            <td className="fw-medium">이용 1일 전</td>
                                            <td>총결제금액의 50% 차감</td>
                                          </tr>
                                          <tr>
                                            <td className="fw-medium">이용 당일</td>
                                            <td>환불불가능</td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <br />
                              <hr />
                              <Row className="gy-3">
                                <Col sm={12}>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="cancelRuleAgree"
                                      checked={cancelRuleAgree}
                                      onChange={(e) => {
                                        setCancelRuleAgree(e.target.checked);
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="cancelRuleAgree">
                                      동의하기
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Card>

                            <Card className="p-4 border shadow-none mb-0 mt-4">
                              <CardHeader className="border-0">
                                <Row className="g-4 align-items-center">
                                  <div className="col-sm">
                                    <div>
                                      <h6 className="mb-3 ff-secondary fw-semibold lh-base">결제동의</h6>
                                    </div>
                                  </div>
                                </Row>
                              </CardHeader>
                              <Row className="gy-3">
                                <Col sm={12}>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="purchaseAllAgree"
                                      checked={purchaseAllAgree}
                                      onChange={(e) => {
                                        toggleAllAgree(e.target.checked);
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="purchaseAllAgree">
                                      전체 동의하기
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                              <br />
                              <hr />
                              <Row className="gy-3">
                                <Col sm={12}>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="purchaseOneAgree"
                                      checked={purchaseOneAgree}
                                      onChange={(e) => {
                                        togglePurchaseAgreeOptions(e.target.checked);
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="purchaseOneAgree">
                                      (필수)구매조건 확인 및 결제진행에 동의
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </div>

                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-danger btn-label previestab"
                              onClick={() => {
                                toggleTab(activeTab - 1);
                              }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2 bg-danger"></i>
                              상품정보
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-label right ms-auto nexttab"
                              onClick={() => {
                                processPayment();
                              }}
                              disabled={!cancelRuleAgree || !purchaseAllAgree}
                            >
                              <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2 bg-danger"></i>
                              결제하기
                            </button>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </BgFrame>
  );
};

export default OrderProcess;
