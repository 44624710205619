import React from 'react';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoLight from "../../assets/images/camp-logo.png";
import { CLIENT_ID, CLIENT_SECRET } from '../../common/social/naver/api';
import { removeAccessToken, setAccessToken } from './auth';
import { BACKEND_HOST } from '../../api';

const NaverPage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [naverToken, setNaverToken] = React.useState(null);
    const [hasUser, setHasUser] = React.useState(false);
    const [responseAuthNum, setResponseAuthNum] = React.useState(null);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            phoneNum: '',
            authNum: 0,
        },
        validationSchema: Yup.object({
            phoneNum: Yup.number().positive().integer().required("전화번호를 입력해주세요.('-' 제외)"),
            authNum: Yup.number().test('check-authnum', "올바른 인증번호를 입력해주세요.", (val, ctx) => {return (responseAuthNum===null||(responseAuthNum.toString()===val.toString()));}),
        }),
        onSubmit: (values) => {
            let body = { "phone_num": '0'+(values.phoneNum).toString() }
            if (responseAuthNum === null) {
                fetch(
                    `${BACKEND_HOST}/auth/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body)
                })
                .then((response) => response.json())
                .then((data) => {
                    setResponseAuthNum(data['auth_num']);
                })
                .catch((e) => {console.log(e);});
            }
            else {
                fetch(
                    `${BACKEND_HOST}/api/token/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({...body, "naver_token":naverToken})
                })
                .then((response) => response.json())
                .then((data) => {
                    localStorage.setItem("refresh_token", data["refresh_token"]);
                    setAccessToken(data["access_token"])
                    navigate(`/`);
                })
                .catch((e) => {
                    console.log(e); 
                    // removeAccessToken();
                });
            }
        }
    });

    React.useEffect(() => {
        if (!location.hash) return;
        const token = location.hash.split('=')[1].split('&')[0];
        setNaverToken(token)
        fetch(
            `${BACKEND_HOST}/api/token/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"naver_token":token})
        })
        .then((response) => response.json())
        .then((data) => {
            setHasUser(data["has_user"]);
            if (data["has_user"]) {
                localStorage.setItem("refresh_token", data["token"]["refresh_token"]);
                setAccessToken(data["token"]["access_token"])
                navigate("/");
            }
        })
        .catch((e) => {console.log(e);});
    }, [location, navigate]);

    return (
        <React.Fragment>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="150" />
                                        </Link>
                                    </div>
                                    {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card>
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">NAVER LogIn</h5>
                                            <p className="text-muted">전화번호 인증을 해주세요.</p>
                                        </div>
                                        {/* {error && error ? (<Alert color="danger"> {error} </Alert>) : null} */}
                                        {
                                            (hasUser) ? 
                                            null : 
                                            <div className="p-2 mt-4">
                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    action="#">

                                                    <div className="mb-3">
                                                        <Label htmlFor="phoneNum" className="form-label">휴대폰 번호</Label>
                                                        <Input
                                                            name="phoneNum"
                                                            className="form-control"
                                                            placeholder="전화번호를 입력해주세요.('-' 제외)"
                                                            type="number"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.phoneNum && validation.errors.phoneNum ? true : false
                                                            }
                                                        />
                                                        {validation.touched.phoneNum && validation.errors.phoneNum ? (
                                                            <FormFeedback type="invalid">{validation.errors.phoneNum}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    {
                                                        (responseAuthNum !== null) ? 
                                                        <React.Fragment>
                                                            <div className="mb-3">
                                                                {/* <div className="float-end">
                                                                    <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                                </div> */}
                                                                <Label className="form-label" htmlFor="password-input">인증번호</Label>
                                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                                    <Input
                                                                        name="authNum"
                                                                        type="number"
                                                                        className="form-control pe-5"
                                                                        placeholder="인증번호를 입력해주세요."
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.authNum && validation.errors.authNum ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.authNum && validation.errors.authNum ? (
                                                                        <FormFeedback type="invalid">{validation.errors.authNum}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <Button color="success" className="btn btn-success w-100" type="submit">Sign In</Button>
                                                            </div>
                                                        </React.Fragment> : 
                                                        <div className="mt-4">
                                                            <Button color="success" className="btn btn-success w-100" type="submit">인증번호 받기</Button>
                                                        </div>
                                                    }
                                                </Form>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
        </React.Fragment>
    );
};

export default NaverPage;