// TEMPORARY
// export const BACKEND_HOST = "http://127.0.0.1:8000/"
export const BACKEND_HOST = "https://total.amazingticket.site"

const fecthHandler = async (response) => {
    if (response.ok) {
        return await response.json();
    } else {
        // console.log(response.data);
        // console.log(response.status)
        // console.log(response.ok)
        // console.log(response.body)
        let message = response.status + ' An error occurred while fetching the data.';
        if (response.body) {
            message = await response.text(); 
            try {
                const _message = JSON.parse(message);
                message = _message
              } catch (e) {
                console.log(e);
              }
        }
        throw new Error(message);
    }
}

export { fecthHandler }