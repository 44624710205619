import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Card, CardBody, CardHeader, Col, Collapse, Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { getAccessToken } from "../../../Authentication/auth";
import UserVoucherGiftUseModal from "../../../../Components/modal/UserVoucherGiftUseModal";
import jwtDecode from "jwt-decode";
import { BACKEND_HOST } from "../../../../api";
import moment from "moment";

const Item = styled.div`
    border-radius: 0.5vh;
    box-shadow: 3px 5px 5px hsl(0deg 0% 0% / 0.22);
    background-color:white;
    opacity:${props => props.visible ? "1" : "0.5"};
    :hover{
        cursor: ${props => props.visible ? "pointer" : "default"};
    }
`;

const UserVoucherGift = (props) => {
    const navigate = useNavigate();

    const [items, setitems] = React.useState([])
    const [modalData, setModalData] = React.useState({});
    const [modal, setModal] = React.useState(false);

    function tog_backdrop() {
        setModal(!modal);
    }

    React.useEffect(() => {
        setdata()
    }, [props]);

    const setdata = () => {
        const accessToken = getAccessToken()
        if (accessToken) {
            let decoded = {}
            try {
                decoded = jwtDecode(accessToken);
            } catch (e) {
                alert("got error decode", accessToken)
            }
            const userId = decoded["user_id"]
            fetch(
                // `${BACKEND_HOST}/accounts/users/${userId}/`, {
                `${BACKEND_HOST}/accounts/users/${userId}/`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    let phone_num = ""
                    if (!!data["전화번호"]) {
                        phone_num = data["전화번호"]
                    }
                    // console.log("phone_num:", phone_num);
                    fetch(
                        `${BACKEND_HOST}/voucher/user-voucher-gift/?recipient=${phone_num}`, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            // console.log(data)
                            let _vouchers = data.results
                            _vouchers = _vouchers.sort((a, b) => {
                                return (a.voucher_state === "사용안함" && (a.gift_state === "선물수신" || a.gift_state === "선물발신")) ? -1 : +1
                            })
                            _vouchers = _vouchers.map((item) => {
                                
                                return {
                                    ...item,
                                    remaining: moment(item.expire_date).startOf('day').diff(moment().startOf('day'), 'days') + 1
                                }
                            })
                            setitems(_vouchers);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                })
                .catch((e) => {
                    console.log(e);
                });
        }

    }

    const handelReceiveGift = (giftId) => {
        if (window.confirm("선물을 수령하시겠습니까?")) {
            const accessToken = getAccessToken()
            fetch(
                `${BACKEND_HOST}/voucher/receive-user-voucher-gift/${giftId}/`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setdata()
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col className="col-12 d-flex flex-wrap justify-content-center">
                    {
                        (items.length !== 0) ?
                            (items.map((item, idx) => {
                                return (

                                    // {
                                    //     "id": 11,
                                    //     "name": "테이블이용권",
                                    //     "sender": "이기환",
                                    //     "phone_num": "01073032625",
                                    //     "user_voucher_id": "2977",
                                    //     "expire_date": "2023-02-14",
                                    //     "voucher_state": "",
                                    //     "gift_state": "선물발신",
                                    //     "send_date": "2022-12-24T11:53:20.743478"
                                    //   }
                                    <Col lg={5} xxl={5} md={5} sm={9} xs={10} key={idx} className="mx-3 my-2 rounded-3">
                                        <Item visible={item.voucher_state === "사용안함"} onClick={() => {
                                            if (item.voucher_state === "사용안함" && item.gift_state === "선물수신") {
                                                setModalData(item); tog_backdrop();
                                            }
                                        }} className="bg-white">
                                            <div className="d-flex justify-content-between px-2 border-bottom">
                                                <div className="card-title pt-3">{item.name}</div>
                                                {(item.voucher_state === "사용안함" && item.gift_state === "선물수신") ? null : <p className="text-muted pt-3">{item.gift_state}</p>}

                                            </div>
                                            <div className="d-flex py-3">
                                                <div className="avatar-md mx-2">
                                                    <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                                        <img src={item.image} alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    {/* <p className="card-text text-muted">GIFT ID : {item.id} </p> */}
                                                    <p className="card-text text-muted">보낸이 : {item.sender} </p>
                                                    <p className="card-text text-muted">잔여일수 : {item.remaining}일  </p>
                                                    <p className="card-text text-muted">상태 : {item.voucher_state} </p>
                                                    {item.voucher_state === "사용안함" ? <div>
                                                        {item.gift_state === "선물발신" ? <Button
                                                            color="success"
                                                            onClick={(e) => {
                                                                handelReceiveGift(item.id)
                                                            }}
                                                        >
                                                            선물받기
                                                        </Button> : null}
                                                        {item.gift_state === "선물수신" ?  <Button
                                                            color="primary"
                                                            onClick={(e) => {
                                                                setModalData(item); tog_backdrop();
                                                            }}
                                                        >
                                                            사용하기
                                                        </Button> : null}
                                                    </div> : null}
                                                </div>
                                            </div>
                                        </Item>
                                        {/* {item.voucher_state === "사용안함" ?
                                            <Item visible={true} onClick={() => {
                                                if (item.gift_state === "선물수신") {
                                                    setModalData(item); tog_backdrop();
                                                }
                                            }} className="bg-white">
                                                <div className="d-flex justify-content-between px-2 border-bottom">
                                                    <div className="card-title pt-3">{item.name}</div>
                                                    <p className="text-secondary fw-bold pt-3">{item.gift_state === "선물수신" ? "사용가능" : item.gift_state}</p>
                                                </div>
                                                <div className="d-flex py-3">
                                                    <div className="avatar-md mx-2">
                                                        <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                                            <img src={item.image} alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-1">
                                                        <p className="card-text text-muted">GIFT ID : {item.id} </p>
                                                        <p className="card-text text-muted">보낸이 : {item.sender} </p>
                                                        <p className="card-text text-muted">만기일 : {item.expire_date}  </p>
                                                        <p className="card-text text-muted">교환권 상태 : {item.voucher_state} </p>
                                                        <p className="card-text text-muted">상태 : {item.gift_state} </p>
                                                        <div>
                                                            {item.gift_state === "선물발신" ? <Button
                                                                color="success"
                                                                onClick={(e) => {
                                                                    handelReceiveGift(item.id)
                                                                }}
                                                            >
                                                                선물받기
                                                            </Button> : <Button
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    setModalData(item); tog_backdrop();
                                                                }}
                                                            >
                                                                사용하기
                                                            </Button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Item> :
                                            <Item visible={false} className="bg-white">
                                                <div className="d-flex justify-content-between px-2 border-bottom">
                                                    <div className="card-title pt-3">{item.name}</div>
                                                    <p className="text-muted pt-3">사용불가</p>
                                                </div>
                                                <div className="d-flex py-3">
                                                    <div className="avatar-md mx-2">
                                                        <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                                            <img src={item.image} alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-1">
                                                        <p className="card-text text-muted">보낸이 : {item.sender} </p>
                                                        <p className="card-text text-muted">만기일 : {item.expire_date} </p>
                                                        <p className="card-text text-muted">교환권 상태 : {item.voucher_state} </p>
                                                        <p className="card-text text-muted">상태 : {item.gift_state} </p>
                                                    </div>
                                                </div>
                                            </Item>
                                            } */}

                                    </Col>
                                )
                            })) :
                            <div className="d-flex justify-content-center" style={{ alignItems: 'center', height: 150 }}>선물함이 비었습니다.</div>
                    }
                </Col>
            </Row>

            <UserVoucherGiftUseModal
                setVisible={setModal}
                visible={modal}
                info={modalData}
                setInfo={setModalData}
                navigate={navigate}
                needRefresh={setdata}
            />

        </React.Fragment>
    )
}
export default UserVoucherGift
