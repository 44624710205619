import React from 'react';
import { Spinner } from 'reactstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loader = (props) => {
    return (
        <React.Fragment>
            <div className="d-flex justify-content-center mx-2 mt-2">
                {
                    (props.loading) ? 
                    <Spinner color="primary"> Loading... </Spinner> :
                    <span>No Data.</span>
                }
                
            </div>
            {
                (props.error) ? 
                toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }) :
                null
            }
        </React.Fragment>
    );
};

export default Loader;
