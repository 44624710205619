import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'
import { BACKEND_HOST } from '../../api'
import { setAccessToken } from './auth'

const SecretLogin = () => {
    const navigate = useNavigate()

    const [phonenum, setPhonnum] = useState("")
    const [password, setPassword] = useState("")
  return (
    <div>
        <form>
            <label className="form-label" htmlFor="idinput">ID</label>
            <input id='idinput' value={phonenum} onChange={(e) => setPhonnum(e.currentTarget.value)} type="text"></input>
            <label className="form-label" htmlFor="pwdinput">PW</label>
            <input id='pwdinput' value={password} onChange={(e) => setPassword(e.currentTarget.value)} type="text"></input>
        </form>
        <Button onClick={()=> {
            fetch(`${BACKEND_HOST}/api/token/admin/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({"phone_num": phonenum, "password": password})
            })
            .then(response=>response.json())
            .then(data=>{
                setAccessToken(data["access_token"])
                navigate('/my-page')
            })
            .catch(e => console.log(e));
        }} >Login</Button>
    </div>
  )
}

export default SecretLogin