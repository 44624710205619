import React from "react";
import { Modal, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { getAccessToken } from "../../pages/Authentication/auth";
import { QRCodeSVG } from 'qrcode.react';
import { BACKEND_HOST } from "../../api";

const ReservationGiftUseModal = ({
    setVisible, visible, info, setInfo, needRefresh
}) => {

    const [checkModal, setCheckModal] = React.useState(false);
    const [checkQrCode, setCheckQrCode] = React.useState('');

    const togModal = () => {
        setVisible(!visible);
        setCheckQrCode('');
    }

    const togCheckModal = () => {
        setCheckModal(!checkModal);
    }

    const changeReserv = async () => {
        const accessToken = getAccessToken()
        const _data = await fetch(`${BACKEND_HOST}/package/use-reservation-gift/${info.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            // body: JSON.stringify({id: info.id, reservation: "입장"})
        })
        const _json = _data.json()
        return _json
    }

    const getQrCode = async () => {
        const accessToken = getAccessToken()
        const _data = await fetch(`${BACKEND_HOST}/package/reservation-qr/${info.reserv_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
        })
        const _json = _data.json()
        return _json
    }

    return (
        <React.Fragment>
            <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
                <div className="mx-3 my-3">
                    {/* qr코드 받아올 시에 그려야함 */}
                    {!info.depositRequired ? <div className="my-3 d-flex justify-content-center  ">
                        {checkQrCode ?
                            <QRCodeSVG value={checkQrCode} />
                            :
                            <Button onClick={() => {
                                getQrCode().then(obj => {
                                    console.log(obj);
                                    setCheckQrCode(obj.qr);
                                })
                            }}
                                className="btn btn-warning"
                            >QR 생성하기</Button>
                        }
                    </div> : null}
                    <ul className="list-group">
                        <li className="list-group-item">예약ID : {info.reserv_id}</li>
                        <li className="list-group-item">예약상품 : {info.name}</li>
                        <li className="list-group-item">예약일 : {info.reservationDate}</li>
                        <li className="list-group-item">자리 : {info.spot}</li>
                        <li className="list-group-item">상태 : {info.reserv_state}</li>
                        {info.options && info.options.length ? <li className="list-group-item">
                            <span>옵션: </span>
                            {info.options.map((item, idx, obj) => {
                                return <span key={idx}>
                                    {item.name}
                                    {idx !== obj.length - 1 ? ", " : ""}
                                </span>
                            })}
                        </li> : null}
                    </ul>
                    {info.depositRequired ?
                        <div className="mt-4">
                            <p>체크인 하기전에 청소보증금을 결제해 주세요</p>
                            <p style={{ textAnchor: "middle" }}>
                                <i className="ri ri-information-line label-icon align-middle fs-20"></i>&nbsp;
                                청소보증금이란?<br />
                                청결한 시설 관리 및 비품 관리를 위해 패키지 상품 구매 시 청소보증금이 결제됩니다.<br />
                                시설 이용 후 정리를 완료하고 체크아웃 버튼을 누르면 관리자가 정리 사항을 확인합니다.<br />
                                <span style={{ color: "red" }}>체크아웃 요청이 승인 되면 자동 환불됩니다.</span></p>
                        </div> : null}
                </div>
                <div className="d-flex justify-content-around mx-1">
                    <Button color="light ms-2 my-2" onClick={() => { setInfo({}); setVisible(!visible); setCheckQrCode(''); }}>
                        닫기
                    </Button>
                    {info.depositRequired ?
                        <Button onClick={() => {
                            window.location.href = `/add-options-order?id=${info.reserv_id}&deposit=true`;
                        }} className="btn btn-success ms-2 my-2">청소보증금 결제</Button> :
                        <Button onClick={() => {
                            setVisible(!visible);
                            setCheckModal(!checkModal);
                        }} className="btn btn-success ms-2 my-2">체크인</Button>}
                </div>
            </Modal>
            <Modal toggle={togCheckModal} isOpen={checkModal} centered>
                <div className="my-4 mx-2">
                    <h5 className="modal-title mb-3" id="staticBackdropLabel">예약상품 사용</h5>
                    <p className="text-muted mb-4">정말 사용하시겠습니까</p>
                    <p className="text mb-4">체크아웃 요청을 하지 않으면 청소보증금 환불이 불가할 수 있습니다.</p>
                    <div className="hstack gap-2 justify-content-around">
                        <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => { setCheckModal(false); }}><i className="ri-close-line me-1 align-middle"></i> 사용안함 </Link>
                        <Link to="#" className="btn btn-success" onClick={() => {
                            setCheckModal(false);
                            changeReserv().then((obj) => {
                                needRefresh()
                            })
                        }}><i className="ri-check-line me-1 align-middle "></i>체크인</Link>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}
export default ReservationGiftUseModal