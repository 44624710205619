import React from "react";

import Navbar from "../../../Components/Common/navbar";
import InquiryFormSection from "./InquiryFormSection";

const InquiryForm = () => {

    return (
        <div className="layout-wrapper landing" style={{minHeight: "100vh"}}>
            <Navbar activeBar={5}/>
            <InquiryFormSection />
        </div>
    );
}

export default InquiryForm;