import React from 'react';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoLight from "../../assets/images/camp-logo.png";
import { REST_API_KEY, REDIRECT_URI } from "../../common/social/kakao/api";
import { fetchErrorHandling, removeAccessToken, setAccessToken, setKakaoAccessToken } from './auth';
import { BACKEND_HOST } from '../../api';

const KakaoPage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [kakaoToken, setKakaoToken] = React.useState(null);
    const [hasUser, setHasUser] = React.useState(false);
    const [responseAuthNum, setResponseAuthNum] = React.useState(null);
    const [redirect, setRedirect] = React.useState(`/`);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            phoneNum: '',
            authNum: 0,
        },
        validationSchema: Yup.object({
            phoneNum: Yup.number().positive().integer().required("전화번호를 입력해주세요.('-' 제외)"),
            authNum: Yup.number().test('check-authnum', "올바른 인증번호를 입력해주세요.", (val, ctx) => { return (responseAuthNum === null || (responseAuthNum.toString() === val.toString())); }), // toString error
        }),
        onSubmit: (values) => {
            let body = { "phone_num": '0' + (values.phoneNum).toString() }
            if (responseAuthNum === null) {
                fetch(
                    `${BACKEND_HOST}/auth/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body)
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setResponseAuthNum(data['auth_num']);
                    })
                    .catch((e) => { console.log(e); });
            }
            else {
                fetch(
                    `${BACKEND_HOST}/api/token/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ ...body, "kakao_token": kakaoToken })
                })
                    // .then((response) => response.json())
                    .then((response) => fetchErrorHandling(response))
                    .then((data) => {
                        localStorage.setItem("refresh_token", data["refresh_token"]);
                        setAccessToken(data["access_token"])
                        navigate(redirect);
                    })
                    .catch((e) => {
                        console.log(e);
                        // removeAccessToken()
                    });
            }
        }
    });



    React.useEffect(() => {
        const urlArr = location.search.split('=');
        const KAKAO_CODE = urlArr[1].split('&')[0];
        const state = urlArr[2];

        const redirectOption = state.split("--")[1];
        if (redirectOption === "V") setRedirect(`/reception/2`);
        else if (redirectOption === "P") setRedirect(`/purchases`);

        fetch(`https://kauth.kakao.com/oauth/token`, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: `grant_type=authorization_code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${KAKAO_CODE}`
        })
            .then((response) => response.json())
            .then((data) => {
                setKakaoToken(data["access_token"]);
                setKakaoAccessToken(data["access_token"]);
                fetch(
                    `${BACKEND_HOST}/api/token/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ "kakao_token": data["access_token"] })
                })
                    // .then((response) => response.json())
                    .then((response) => fetchErrorHandling(response))
                    .then((data) => {
                        setHasUser(data["has_user"]);
                        if (data["has_user"]) {
                            localStorage.setItem("refresh_token", data["token"]["refresh_token"]);
                            setAccessToken(data["token"]["access_token"])

                            if (state.split("--")[1] === "V") navigate(`/reception/2`);
                            else if (state.split("--")[1] === "P") navigate(`/purchases`);
                            else navigate(`/`);
                        }
                    })
                    .catch((e) => { console.log(e); });
            })
            .catch((e) => { console.log(e); })
    }, []);

    return (
        <React.Fragment>
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" height="150" />
                                    </Link>
                                </div>
                                {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">KAKAO LogIn</h5>
                                        <p className="text-muted">전화번호 인증을 해주세요.</p>
                                    </div>
                                    {/* {error && error ? (<Alert color="danger"> {error} </Alert>) : null} */}
                                    {
                                        (hasUser) ?
                                            null :
                                            <div className="p-2 mt-4">
                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    action="#">

                                                    <div className="mb-3">
                                                        <Label htmlFor="phoneNum" className="form-label">휴대폰 번호</Label>
                                                        <Input
                                                            name="phoneNum"
                                                            className="form-control"
                                                            placeholder="전화번호를 입력해주세요.('-' 제외)"
                                                            type="number"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.phoneNum && validation.errors.phoneNum ? true : false
                                                            }
                                                        />
                                                        {validation.touched.phoneNum && validation.errors.phoneNum ? (
                                                            <FormFeedback type="invalid">{validation.errors.phoneNum}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    {
                                                        (responseAuthNum !== null) ?
                                                            <React.Fragment>
                                                                <div className="mb-3">
                                                                    {/* <div className="float-end">
                                                                    <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                                </div> */}
                                                                    <Label className="form-label" htmlFor="password-input">인증번호</Label>
                                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                                        <Input
                                                                            name="authNum"
                                                                            type="number"
                                                                            className="form-control pe-5"
                                                                            placeholder="인증번호를 입력해주세요."
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={
                                                                                validation.touched.authNum && validation.errors.authNum ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.authNum && validation.errors.authNum ? (
                                                                            <FormFeedback type="invalid">{validation.errors.authNum}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <Button color="success" className="btn btn-success w-100" type="submit">Sign In</Button>
                                                                </div>
                                                            </React.Fragment> :
                                                            <div className="mt-4">
                                                                <Button color="success" className="btn btn-success w-100" type="submit">인증번호 받기</Button>
                                                            </div>
                                                    }
                                                </Form>
                                            </div>
                                    }
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default KakaoPage;