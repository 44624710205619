import React, { useMemo, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    Label,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    CardBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";
import moment from "moment";
import Loader from "../../../Components/Common/Loader";
import CommentList from "./CommentList";
import { useUserStore } from "../../../store/store";
import PrevInquiryListSection from "./PrevInquiryListSection";


const InquiryDetailSection = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const page = params.get("page") || 0;

    const user = useUserStore(state => state.user)

    const [inquiry, setInquiry] = React.useState(null);

    const [permission] = useUserStore((state) => [state.permission]);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [deleteModal, setDeleteModal] = React.useState(false);

    function togDeleteModal() {
        setDeleteModal(!deleteModal);
    }

    const deleteData = () => {
        const accessToken = getAccessToken()
        if (accessToken) {
            fetch(
                `${BACKEND_HOST}/inquiry/article-write/${id}/`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
            )
                // .then((response) => response.json())
                .then((data) => {
                    // setInquiry(data);
                    console.log(data)
                    navigate(`/inquiry`);
                })
                .catch((e) => { console.log(e); });
        }
    }

    const setExtraInfo = (articleId, need, extraInfo) => {
        const accessToken = getAccessToken()
        if (accessToken) {
            fetch(
                `${BACKEND_HOST}/inquiry/extra-info/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    article_id: articleId,
                    need_extra_info: need,
                    extra_info: extraInfo,
                }),
            }
            )
                .then((response) => response.json())
                .then((data) => {
                    refetch(id)
                })
                .catch((e) => { console.log(e); });
        }
    }

    const refetch = (id) => {
        setLoading(true)
        setError(null)
        const accessToken = getAccessToken()
        fetch(`${BACKEND_HOST}/inquiry/articles/${id}/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.error) {
                    setError(data.error);
                } else setInquiry(data);
            })
            .catch((e) => { console.log(e); setError(e); })
            .finally(() => setLoading(false))
    }

    React.useEffect(() => {
        refetch(id)
    }, [id]);

    const article_username = useMemo(() => {
        let _username = ""
        if (inquiry) {
            if ((!!permission && permission.permission) || (user && inquiry.user_id == user.id)) {
                _username = inquiry.username
            } else {
                _username = `${inquiry.username[0] + '*'.repeat(inquiry.username.length - 1)}`
            }
            if (!!inquiry.user_permission?.permission && !!permission && permission.permission) {
                _username = `관리자(${_username})`
            }
            if (!!inquiry.user_permission?.permission && (!permission || !permission.permission)) {
                _username = `관리자`
            }
        }
        return _username
    }, [inquiry])

    if (loading)
        return (<Container><Row className="mt-4 justify-content-center"><Loader loading={loading} /></Row></Container>)
    if (!inquiry || error)
        return (<Container>
            <Row className="mt-4 justify-content-center" style={{ marginTop: "60px" }}>
                <span>글쓴이 혹은 관리자만 글을 볼 수 있습니다.</span>
            </Row>
        </Container>)
    return (
        <React.Fragment>
            <div className="mt-4">
                <Container>

                    <div style={{margin: "10px", padding: "10px"}}>
                    <Button color="white" className="btn-ghost-dark add-btn" onClick={() => {
                        navigate(`/inquiry?page=${page}`)
                    }} id="edit-btn">
                        목록으로 돌아가기
                    </Button>
                    </div>

                    <Row className="justify-content-center">
                        <Col>

                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 d-flex flex-row align-items-center justify-content-between">
                                        <div className="" style={{ width: "calc(100% - 180px)" }}>
                                            <div>
                                                <h1 className="mb-3 ff-secondary fw-semibold lh-base">{inquiry.title}</h1>
                                            </div>
                                        </div>

                                        {
                                            (!!permission && permission.permission > 0) || (user && user.id === inquiry.user_id) ?
                                                <div className="" style={{ width: "180px" }}>
                                                    <div className="d-flex justify-content-end gap-2">
                                                        {(!!permission && permission.permission > 0) ?
                                                            <>
                                                                {!inquiry.need_extra_info ?
                                                                    <Button size="sm" className="btn-soft-danger add-btn me-1" onClick={() => {
                                                                        const result = window.prompt("주의할 내용을 입력해주세요.")
                                                                        setExtraInfo(inquiry.id, true, result)
                                                                    }} id="inquiry-alert-btn">
                                                                        주의!
                                                                    </Button> :
                                                                    <Button size="sm" className="btn-soft-success add-btn me-1" onClick={() => {
                                                                        setExtraInfo(inquiry.id, false, null)
                                                                    }} id="inquiry-alert-btn">
                                                                        주의 X
                                                                    </Button>}
                                                            </> : null}
                                                        <Button size="sm" className="btn-soft-success add-btn me-1" onClick={() => { navigate(`/inquiry/form/${id}`) }} id="edit-btn">
                                                            수정
                                                        </Button>
                                                        <Button size="sm" className="btn-soft-danger add-btn me-1" onClick={() => { togDeleteModal(); }} id="delete-btn">
                                                            삭제
                                                        </Button>
                                                    </div>
                                                </div> :
                                                null
                                        }
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <p style={{ whiteSpace: "pre-wrap", color: "gray" }}>{`${moment(inquiry.written_date).format('YYYY-MM-DD HH:mm')}\n`}</p>
                                            <p style={{ whiteSpace: "pre-wrap" }}>
                                                <span><img src={inquiry.user_permission?.permission ? '/amazingpark-logo.png' : inquiry.profile_img || `${BACKEND_HOST}/media/ProfileBlank.png`} alt="" className="rounded-circle" style={{ width: "32px", height: "32px", objectFit: "cover" }} /></span>
                                                <span className="mx-2">{article_username}</span>
                                            </p>
                                            {(user && (user.permission === "스탭" || user.permission === "관리자")) && inquiry.phone_num && <p style={{ whiteSpace: "pre-wrap" }}>
                                                연락처: <a href={`tel:${inquiry.phone_num}`}>{inquiry.phone_num}</a>
                                            </p>}
                                            {inquiry.address && <p style={{ whiteSpace: "pre-wrap" }}>{`문의주소: ${inquiry.address}`}</p>}

                                            {permission?.permission && inquiry.need_extra_info ? <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>{`주의: ${inquiry.extra_info ? inquiry.extra_info : "주의가 필요합니다."}`}</p> : null}

                                        </div>
                                    </Row>
                                    <br />
                                    <br />
                                    {/* <hr /> */}
                                    <Row className="g-4 align-items-center mb-4">
                                        {inquiry.images ? inquiry.images.map(image => (<div key={image.id}><img src={`${image.image}`} style={{ maxWidth: 300 }} /></div>)) : null}
                                    </Row>
                                    <Row className="g-4 align-items-center">
                                        <div dangerouslySetInnerHTML={{ __html: inquiry.content }} />
                                    </Row>
                                    {!(inquiry.mode === "4") ? <Row className="mt-4">
                                        <CommentList articleId={inquiry.id} />
                                    </Row> : <Row className="mt-4">
                                        <div style={{ margin: "20px" }}>
                                            <span></span>
                                        </div>
                                    </Row>}

                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm-3 ms-auto">
                                            <div className="d-flex justify-content-sm-end gap-2">
                                                <Button color="white" className="btn-ghost-dark add-btn me-1" onClick={() => {
                                                    navigate(`/inquiry?page=${page}`)
                                                }} id="edit-btn">
                                                    목록보기
                                                </Button>
                                            </div>
                                        </div>
                                    </Row>

                                </CardBody>

                                {(!!permission && permission.permission > 0) ? <div>
                                    <br />
                                    <br />
                                    <Label className="form-label">{inquiry.username}님의 이전 문의글</Label>
                                    <PrevInquiryListSection targetUser={inquiry.phone_num} />
                                </div> : null}

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Delete Modal */}
            <Modal id="topmodal" isOpen={deleteModal} toggle={() => { togDeleteModal(); }} >
                <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { togDeleteModal(); }}>
                    삭제하기
                </ModalHeader>
                <ModalBody className="text-center p-5">
                    <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="loop" style={{ width: "120px", height: "120px" }} colors="primary:#f7b84b,secondary:#f06548">
                    </lord-icon>
                    <div className="mt-4">
                        <h5 className="mb-3">해당 글을 삭제하시겠습니까?</h5>
                        {/* <p className="text-muted mb-4"> The transfer was not successfully received by us. the email of the recipient wasn't correct.</p> */}
                        <div className="hstack gap-2 justify-content-center">
                            <button className="btn bg-gradient-light fw-medium" onClick={() => { togDeleteModal(); }}><i className="ri-close-line me-1 align-middle"></i>닫기</button>
                            <button className="btn btn-danger" onClick={() => { deleteData(); setDeleteModal(false); }} >삭제하기</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default InquiryDetailSection;