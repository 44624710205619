import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  CardHeader,
  Input,
  Button
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";

import MyEditor from "../../../Components/Common/MyEditor";
import { getAccessToken } from "../../Authentication/auth";

import { KakaoMapModal } from "../../../Components/Common/ManagerModal";
import { BACKEND_HOST } from "../../../api";


const NoticeEditor = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [title, setTitle] = React.useState("");
    const [content, setContent] = React.useState("");

        
    const [kakaoMapEditModal, setKakaoMapEditModal] = React.useState(false);
    const [mapAddress, setMapAddress] = React.useState("");

    const getUserId = () => {
        const accessToken = getAccessToken()
        if (accessToken) {
            let decoded = {}
            try {
                decoded = jwtDecode(accessToken);
                return decoded["user_id"];
            } catch (e) {
                console.log(e);
                return null;
            }
        }
        else return null;
    }

    const fillStates = (data) => {
        setTitle(data.title);
        setContent(data.content);
    }

    const checkData = () => {
        if (!title || !mapAddress || !content) {
            return null;
        }

        const userId = getUserId()
        if (userId === null) navigate(`/`);
        const data = {
            "site": 4,
            "writer": userId,
            "title": title,
            "address": mapAddress, 
            "content": content
        }
        return data
    }

    const sendData = () => {
        const data = checkData()
        if (data === null) {
            alert("제목과 주소 문의내용 모두 입력해주세요.");
            return;
        }
        else {
            const accessToken = getAccessToken()
            if (accessToken) {
                fetch(
                    `${BACKEND_HOST}/notice/content/`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`
                        },
                        body: JSON.stringify(data)
                    }
                )
                .then((response) => response.json())
                .then((data) => {alert("문의 등록 완료"); navigate(`/notice`)})
                .catch((e) => console.log(e));
            }
        }
    }

    const updateData = () => {
        const data = checkData()
        if (data === null) {
            alert("제목과 내용 모두 입력해주세요.");
            return;
        }
        else {
            const accessToken = getAccessToken()
            if (accessToken) {
                fetch(
                    `${BACKEND_HOST}/notice/content/${id}/`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`
                        },
                        body: JSON.stringify(data)
                    }
                )
                .then((response) => response.json())
                .then((data) => {alert("공지사항 수정 완료"); navigate(`/notice`);})
                .catch((e) => console.log(e));
            }
        }
    }

    React.useEffect(() => {
        if (id !== '0') {
            const accessToken = getAccessToken()
            if (accessToken) {
                fetch(
                    `${BACKEND_HOST}/notice/content/${id}/`, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                .then((response) => response.json())
                .then((data) => {
                    fillStates(data)
                })
                .catch((e) => {console.log(e);})
            }
        }
    }, [id]);

    return (
        <div className="mt-4">
            <Container>
                <Row className="g-4 mb-3">
					<div className="col-sm-auto">
						<div>
							<h4 className="card-title mb-0 flex-grow-1">가맹문의 등록 및 수정</h4>
						</div>
					</div>
					<div className="col-sm-3 ms-auto">
						<div className="d-flex justify-content-sm-end gap-2">
                            {
                                (id !== '0') ? 
                                <Button className="btn-soft-success add-btn me-1" onClick={() => {updateData()}} id="create-btn">
                                    수정하기
                                </Button> : 
                                <Button className="btn-soft-success add-btn me-1" onClick={() => {sendData()}} id="create-btn">
                                    등록하기
                                </Button>
                            }
							<Button className="btn-soft-danger add-btn me-1" onClick={() => {navigate(`/notice`)}} id="cancel-btn">
								취소하기
							</Button>
						</div>
					</div>
				</Row>

                <Row className="justify-content-center">
                    <Col lg={12}>
                        <Card id="customerList">
                            <CardBody>
                                <div className="live-preview">
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
												<Label htmlFor="title" className="form-label">제목</Label>
												<Input type="text" className="form-control" placeholder="제목" id="title" value={title} onChange={(e) => (setTitle(e.target.value))} maxLength="100"/>
											</div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={12}>
                        <Card id="customerList">
                            <CardBody>
                                <div className="live-preview">
                                    <Row>
                                        <Col md={6}>
                                        <div className="d-flex justify-content-sm-end gap-2 mt-2">
                                            <Button className="btn-info add-btn me-1" onClick={() => setKakaoMapEditModal(true)} id="create-btn">
                                                주소입력
                                            </Button>
                                        </div>
                                        <div className="d-flex justify-content-sm-end gap-2 mt-2">
                                            <Label htmlFor="title" className="form-label">{mapAddress}</Label>  
                                        </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    
                    <Col lg={12}>
                        <Card id="customerList">
                            <CardHeader>
								<h4 className="card-title mb-0">문의내용</h4>
							</CardHeader>
                            <CardBody>
                                <div className="live-preview">
                                    <Row>
                                        <Col md={12}>
                                            <MyEditor value={content} onChange={setContent}/>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
      <KakaoMapModal
        visible={kakaoMapEditModal}
        setVisible={setKakaoMapEditModal}
        setAddress={setMapAddress}
        navigate={navigate}
        isNotice
      />
        </div>
    );
}

export default NoticeEditor;