import React from "react";
import { Modal, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { getAccessToken } from "../../pages/Authentication/auth";
import { QRCodeSVG } from 'qrcode.react';
import { BACKEND_HOST } from "../../api";

const UserVoucherGiftUseModal = ({
    setVisible, visible, info, setInfo, needRefresh
}) => {

    const [checkModal, setCheckModal] = React.useState(false);
    const [checkQrCode, setCheckQrCode] = React.useState('');

    const togModal = () => {
        setVisible(!visible);
        setCheckQrCode('');
    }

    const togCheckModal = () => {
        setCheckModal(!checkModal);
    }

    const changeReserv = async () => {
        const accessToken = getAccessToken()
        const _data = await fetch(`${BACKEND_HOST}/voucher/use-user-voucher-gift/${info.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            // body: JSON.stringify({id: info.id, uservoucher: "입장"})
        })
        const _json = _data.json()
        return _json
    }

    return (
        <React.Fragment>
            <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
                <div className="mx-3 my-3">
                    {/* qr코드 받아올 시에 그려야함 */}
                    <div className="my-3 d-flex justify-content-center  ">
                        {/* {checkQrCode ?
                            <QRCodeSVG value={checkQrCode} />
                            :
                            <Button onClick={() => {
                                getQrCode().then(obj => {
                                    console.log(obj);
                                    setCheckQrCode(obj.qr);
                                })
                            }}
                                className="btn btn-warning"
                            >QR 생성하기</Button>
                        } */}
                    </div>
                    <ul className="list-group">

                        <li className="list-group-item">ID : {info.voucher_id}</li>
                        <li className="list-group-item">상품명 : {info.name}</li>
                        <li className="list-group-item">만기일 : {info.expire_date}</li>
                        <li className="list-group-item">상태 : {info.voucher_state}</li>
                    </ul>
                </div>
                <div className="d-flex justify-content-around mx-1">
                    <Button color="light ms-2 my-2" onClick={() => { setInfo({}); setVisible(!visible); setCheckQrCode(''); }}>
                        닫기
                    </Button>
                    <Button onClick={() => {
                        setVisible(!visible);
                        setCheckModal(!checkModal);
                    }}
                        className="btn btn-success ms-2 my-2"
                    >직원확인</Button>
                </div>
            </Modal>
            <Modal toggle={togCheckModal} isOpen={checkModal} centered>
                <div className="my-4 mx-2">
                    <h5 className="modal-title mb-3" id="staticBackdropLabel">교환권 사용</h5>
                    <p className="text-muted mb-4">정말 사용하시겠습니까</p>
                    <div className="hstack gap-2 justify-content-around">
                        <Link to="#" className="btn btn-success" onClick={() => {
                            setCheckModal(false);
                            changeReserv().then((obj) => {
                                // console.log
                                // alert("교환권 상태가 변경되었습니다!")
                                // alert("교환권 상태가 변경되었습니다! " + JSON.stringify(obj))
                                needRefresh()
                            })
                        }}><i className="ri-check-line me-1 align-middle "></i>사용하기</Link>
                        <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => { setCheckModal(false); }}><i className="ri-close-line me-1 align-middle"></i> 사용안함 </Link>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}
export default UserVoucherGiftUseModal