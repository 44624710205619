import moment from 'moment'
import React from 'react'
import { Button } from 'reactstrap'
import { BACKEND_HOST } from '../../../api'
import { getUserId } from '../../Authentication/auth'
import SubCommentBox from './SubCommentBox'

function CommentBox({
    id, user_id, username, content, profile_img, images, written_date, onReply, subComments, onDelete
}) {
    const self_user_id = getUserId()
    return (
        <div key={id}>
            <div className="d-flex flex-row justify-content-start align-content-start gap-3 m-2">
                {/* <a href="#" className="pull-left"> */}
                <div><img src={profile_img || `${BACKEND_HOST}/media/ProfileBlank.png`} alt="" className="rounded-circle" style={{ width: "38px" }} /></div>
                <div className="media-body">
                    <span className=""><strong className="text-primary">{username}</strong></span>
                    <span className="text-muted mx-2">
                        <small className="text-muted" style={{ fontSize: "11px" }}>{moment(written_date).format('YYYY-MM-DD HH:mm')}</small>
                    </span>
                    <span><span className='btn btn-link' style={{ padding: "2px", boxShadow: "none" }} onClick={() => onReply({
                        id, username, content
                    })} >댓글</span></span>
                    {String(self_user_id) === String(user_id) ? <span><span className='btn btn-link' style={{ padding: "2px", boxShadow: "none" }} onClick={() => {
                        if (window.confirm("정말로 댓글을 삭제하시겠습니까?")) onDelete(id)
                    }} >삭제</span></span> : null}
                    <p className='mt-1'>
                        {content}
                    </p>
                </div>
            </div>
            <div>
                {subComments ? subComments.map(item => <SubCommentBox key={item.id} {...item} onDelete={(_id) => onDelete(_id)} />) : null}
            </div>
        </div>
    )
}

export default CommentBox