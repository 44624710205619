import React from "react";

import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Card, Nav, Navbar, NavItem, NavLink, Row, Col, CardBody } from 'reactstrap';
import ReservationList from "./ReservationList";
import TicketPurchaseList from "./TicketPurchaseList";


const PurchaseRecord = () => {
    const navigate = useNavigate();

    // Custom Tabs Bordered
    const [customActiveTab, setcustomActiveTab] = React.useState("1");
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <div className="layout-wrapper landing">
            <Navbar></Navbar>
            <div>
                <Row className="justify-content-center">
                    <Col xxl={12} xl={12} md={12} sm={12}>
                        <Card className='bg-light bg-opacity-50'>
                            <CardBody>
                                <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "1",
                                            })}
                                            onClick={() => {
                                                toggleCustom("1");
                                            }}
                                        >
                                            예약내역
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "2",
                                            })}
                                            onClick={() => {
                                                toggleCustom("2");
                                            }}
                                        >
                                            티켓구매 내역
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                            <div>
                                {customActiveTab === "1" ?
                                    <ReservationList navigate={navigate} /> : null}
                                {customActiveTab === "2" ? 
                                    <TicketPurchaseList navigate={navigate} /> : null}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default PurchaseRecord