import "./App.css";
// import useWindowDimensions from './hooks/useWindowDimension';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/scss/themes.scss";

import Index from "./pages/Landing/OnePage";
import Login from "./pages/Authentication/Login";
import KakaoPage from "./pages/Authentication/KakaoPage";
import NaverPage from "./pages/Authentication/NaverPage";
import ReservationPage from "./pages/Reservation";
import Detail from "./pages/Detail";
import EventProductReception from "./pages/EventProduct/Reception/index";
import MyPage from "./pages/UserPage/MyPage";
import Purchases from "./pages/UserPage/Purchases";
import Notices from "./pages/Notice/NoticeList/index";
import NoticeDetail from "./pages/Notice/NoticeDetail";
import NoticeForm from "./pages/Notice/NoticeForm";
import CampMap from "./pages/CampMap/CampMap";
import OrderPage from "./pages/Order/OrderPage";
import OrderDone from "./pages/Order/OrderPage/OrderDone";
import Ticket from "./pages/Ticket/Ticket";
import AddReserve from "./pages/Reservation/AddReserve";
import AddTicket from "./pages/Ticket/AddTicket";
import SecretLogin from "./pages/Authentication/SecretLogin";
import AddOptionsDetail from "./pages/Detail/AddOptionsDetail";
import AddOptionsOrder from "./pages/Order/AddOptions";
import AddOptionsOrderDone from "./pages/Order/AddOptions/OrderDone";
import CancelOptionsDetail from "./pages/Detail/CancelOptionsDetail";
import InquiryList from "./pages/Inquiry/List";
import InquiryDetail from "./pages/Inquiry/Detail";
import InquiryForm from "./pages/Inquiry/Form";
import OrderTicketMultiPage from "./pages/Order/OrderTicketMultiPage";
import MultiOrderDone from "./pages/Order/OrderTicketMultiPage/MultiOrderDone";

import PhoneLogin from "./pages/Authentication/PhoneLogin";
import { useUserStore } from "./store/store";
import React, { useEffect, useState } from "react";
import { getAccessToken } from "./pages/Authentication/auth";
import { BACKEND_HOST } from "./api";
import AppDownload from "./pages/AppDownload";
import { SITE_ID } from "./assets/meta/meta";
import ShopList from "./pages/Shop/ShopList";
import OrderShopping from "./pages/Order/OrderShopping";
import ShoppingDetail from "./pages/Shop/ShoppingDetail";
import ApplyCampUlsan from "./pages/Events/ApplyCampUlsan";

function App() {
  const [permission, setPermission, user] = useUserStore((state) => [state.permission, state.setPermission, state.user]);
  const [isWebPurchase, setIsWebPurchase] = useUserStore((state) => [state.isWebPurchase, state.setIsWebPurchase]);
  const [isWebPurchaseTicket, setIsWebPurchaseTicket] = useUserStore((state) => [state.isWebPurchaseTicket, state.setIsWebPurchaseTicket]);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/permission/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPermission(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [user]);

  useEffect(() => {
    if (permission) console.log("set user permission:", permission);
  }, [permission]);

  useEffect(() => {
    fetch(`${BACKEND_HOST}/sites/camps/${SITE_ID}/`)
      .then((response) => response.json())
      .then((data) => {
        setIsWebPurchase(data.is_web_purchase);
        setIsWebPurchaseTicket(data.is_web_purchase_ticket);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index isWebPurchase={isWebPurchase} isWebPurchaseTicket={isWebPurchaseTicket} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/phone-login" element={<PhoneLogin />} />
        <Route path="/admin-login" element={<SecretLogin />} />
        <Route path="/kakao" element={<KakaoPage />} />
        <Route path="/naver" element={<NaverPage />} />

        <Route path="/detail/:type/:id" element={<Detail />} />
        <Route path="/reception/:type" element={<EventProductReception />} />
        <Route path="/my-page" element={<MyPage />} />
        <Route path="/my-page/gift-box" element={<MyPage tabparam="gift-box" />} />
        <Route path="/mypage/gift-box" element={<MyPage tabparam="gift-box" />} />
        <Route path="/purchases" element={<Purchases />} />
        <Route path="/notice" element={<Notices />} />
        <Route path="/notice/detail/:id" element={<NoticeDetail />} />
        <Route path="/notice/form/:id" element={<NoticeForm />} />

        <Route path="/inquiry" element={<InquiryList />} />
        <Route path="/inquiry/detail/:id" element={<InquiryDetail />} />
        <Route path="/inquiry/form/:id" element={<InquiryForm />} />

        <Route path="/camp-map" element={<CampMap />} />
        <Route path="/order" element={<OrderPage />} />

        <Route path="/order-ticket-multi" element={<OrderTicketMultiPage />} />
        <Route path="/order-done-multi/:orderId" element={<MultiOrderDone />} />

        <Route path="/order-done/:orderId" element={<OrderDone />} />
        <Route path="/add-reserve" element={<AddReserve />} />
        <Route path="/add-ticket" element={<AddTicket />} />

        <Route path="/add-options-detail/:id" element={<AddOptionsDetail />} />
        <Route path="/add-options-order" element={<AddOptionsOrder />} />
        <Route path="/add-options-order-done/:orderId" element={<AddOptionsOrderDone />} />
        <Route path="/cancel-options-detail/:id" element={<CancelOptionsDetail />} />

        <Route path="/shop" element={<ShopList />} />
        <Route path="/shop/:id" element={<ShoppingDetail />} />
        <Route path="/order-shop" element={<OrderShopping />} />


        <Route path="/apply/camp-ulsan-free-voucher-2023-12" element={<ApplyCampUlsan />} />


        {(!!permission && permission.permission >= 2) || isWebPurchase ? (
          <>
            <Route path="/reservation" element={<ReservationPage />} />
          </>
        ) : (
          <>
            <Route path="/reservation" element={<AppDownload path="reservation" />} />
          </>
        )}

        {(!!permission && permission.permission >= 2) || isWebPurchaseTicket ? (
          <>
            <Route path="/ticket" element={<Ticket />} />
          </>
        ) : (
          <>
            <Route path="/ticket" element={<AppDownload path="ticket" />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
