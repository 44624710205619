import React from "react";

import Navbar from "../../../Components/Common/navbar";
import InquiryDetailSection from "./InquiryDetailSection";

const InquiryDetail = () => {

    return (
        <div className="layout-wrapper landing" style={{minHeight: "100vh"}}>
            <Navbar activeBar={5}/>
            <InquiryDetailSection />
        </div>
    );
}

export default InquiryDetail;