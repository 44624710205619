import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import Calender from './calender';

// Import Images
import imgpattern from "../../assets/images/landing/img-pattern.png";

const Reservation = () => {
    return (
        <React.Fragment>
            <section className="section pb-0 hero-section" id="hero">
                {/* <div className="bg-overlay bg-overlay-pattern"></div> */}
                <Calender />
            </section>
        </React.Fragment>
    );
};

export default Reservation;