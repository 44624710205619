import React, { useEffect } from "react";
import Navbar from "../Components/Common/navbar";
import { Container } from "reactstrap";
import { isMobile, isAndroid, isIOS } from "react-device-detect";

function AppDownload({ path }) {
  const [defaultLink, setDefaultLink] = React.useState("#");

  const android_link = "https://play.google.com/store/apps/details?id=com.yargeu.AmazingCamp&hl=ko-KR";
  const ios_link = "https://apps.apple.com/us/app/amzingticket/id6447417241";

  useEffect(() => {
    if (isMobile) {
      if (isAndroid) {
        setDefaultLink(android_link);
      } else if (isIOS) {
        setDefaultLink(ios_link);
      }
    }
  }, []);

  const contentWidth = window.innerWidth > 540 ? "540px" : "100%";

  return (
    <div className="layout-wrapper landing">
      <Navbar activeBar={path === "reservation" ? 1 : 2} />
      <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", justifyContent: "center", paddingBottom: "100px" }}>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
          <a href={defaultLink} target="_self" rel="noreferrer" style={{ border: "none" }}>
            <img
              src="https://total.amazingticket.site/media/amazingticket_app_banner.jpg"
              // width={window.innerWidth > 540 ? "540px" : "100%"}
              style={{ width: contentWidth }}
              alt="app download"
            />
          </a>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "40px", marginBottom: "10px" }}>
          <div style={{ width: contentWidth }}>
            <p style={{ fontFamily: "Gmarket-M" }}>
              <span style={{ fontFamily: "Gmarket-B" }}>앱 다운로드 안내</span>
              <br />
              안녕하세요. 어메이징캠프입니다.
              <br />
              어메이징캠프 예약은 모바일앱으로 서비스 제공하고 있습니다.
              <br />
              아래 링크를 통해 앱을 다운 받으신 후 이용해주세요. 앱을 통해 더 많은 혜택을 받으실 수 있습니다. <br />
            </p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0", marginBottom: "20px" }}>
          <div style={{ width: contentWidth, display: "flex", justifyContent: "center", alignItems: "center" }}>
            {!isIOS ? (
              <a href={android_link}>
                <img height="90px" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </a>
            ) : null}
            {!isAndroid ? (
              <a href={ios_link}>
                <img height="61px" alt="Download on the App Store" src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png" />
              </a>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AppDownload;
