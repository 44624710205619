import React from "react";
import { Card, Table, Button, ButtonGroup, Modal, ModalBody, ModalHeader, Input, Label, Row, Col, Spinner } from "reactstrap";
import PurchaseTicketModal from "../../../Components/modal/PurchaseTicketModal";
import { QRCodeSVG } from 'qrcode.react';
import { Link, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";
import TicketGiftModal from "../../../Components/modal/TicketGiftModal";
import moment from "moment";

const Item = styled.div`
    border-radius: 0.5vh;
    max-width: 480px;
    box-shadow: 3px 5px 5px hsl(0deg 0% 0% / 0.22);
    background-color:white;
    opacity:${props => props.visible ? "1" : "0.5"};
    :hover{
        cursor: ${props => props.visible ? "pointer" : "default"};
    }
`;

function ShoppingPurchaseList(props) {
    const navigate = useNavigate()

    const [modalBackdrop, setModalBackdrop] = React.useState(false);
    function toggleBackdrop() {
        setModalBackdrop(!modalBackdrop);
    }
    const useConfirm = (message = null, onConfirm, onCancel) => {
        if (!onConfirm || typeof onConfirm !== "function") {
            return;
        }
        if (onCancel && typeof onCancel !== "function") {
            return;
        }

        const confirmAction = () => {
            if (window.confirm(message)) {
                onConfirm();
            } else {
                onCancel();
            }
        };

        return confirmAction;
    };

    const [ticketModal, setTicketModal] = React.useState(false);
    const [selectOrder, setSelectOrder] = React.useState({});
    const [orders, setOrders] = React.useState([]);
    const [giftModal, setGiftModal] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setdata()
    }, [])

    const setdata = () => {
        const accessToken = getAccessToken()
        if (accessToken) {
            setIsLoading(true)
            fetch(
                `${BACKEND_HOST}/shop/item`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("fetching tickets", data);

                    let _items = data.results;
                    // _tickets = _tickets.sort((a, b) => {
                    //     return (a.state === "사용안함" && !a.gifted) ? -1 : +1
                    // })
                    // _tickets = _tickets.map((ticket) => {

                    //     return {
                    //         ...ticket,
                    //     }
                    // })

                    setOrders(_items);
                })
                .catch((e) => {
                    console.log(e);
                }).finally(() => {
                    setIsLoading(false)
                });
        }
        else {
            navigate(`/`);
        }
    }



    return (
        <React.Fragment>
            <Row>

                <Col className="col-12 d-flex flex-wrap justify-content-center gap-4">
                    {isLoading ? <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                        <Spinner animation="border" variant="primary" />
                    </div> : null}

                    {(orders || []).map((order, key) => (
                        <Col lg={5} xxl={5} md={5} sm={9} xs={10} key={order.id} className="">

                            <Item
                                // visible={!order.gifted} 
                                visible={true}
                                className="bg-white">
                                <div className="d-flex justify-content-between px-2 border-bottom" onClick={() => {
                                    if (order.gifted) return;
                                    setSelectOrder(order); toggleBackdrop();
                                }}>
                                    <div className="card-title pt-3">{order.name}</div>
                                    {/* {(!order.gifted && order.state === "사용안함") ? <p className="fw-bold mt-2 btn btn-primary">사용가능</p> : <p className="text-muted pt-3">{order.state === "사용함" ? "사용완료" : "사용불가"}</p>} */}
                                </div>
                                <div className="d-flex py-3 align-items-center justify-content-start" onClick={() => {
                                    if (order.gifted) return;
                                    setSelectOrder(order); toggleBackdrop();
                                }}>
                                    <div className="avatar-md mx-2">
                                        <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                            <img src={`${order.shopping_product.thumbnail}`} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        {/* <div className="card-text text-muted">{order.name + '(' + order.bundleCount + ')'}</div> */}
                                        {/* <div className="card-text text-muted">{order.orderDate}</div> */}
                                        {/* <div className="card-text text-muted">기간: 20{order.expiration_date} 까지</div> */}
                                        {/* <div className="card-text text-muted">잔여일수: {order.remaining}일</div> */}
                                        {/* <p className="card-text text-muted">자세히 보기</p> */}
                                        <div className="card-text text-muted">{order.quantity}개</div>
                                        {order.delivery ? <div className="card-text text-muted">{order.delivery.state}</div> : <div className="card-text text-muted">{order.state}</div>}
                                    </div>
                                </div>

                                <div className="d-flex w-100 flex-column align-items-center px-5">

                                    <div className="d-flex justify-content-center gap-4 pb-3">

                                        {/* <Button
                                            color="danger"
                                            onClick={(e) => {
                                                setSelectOrder(order); setTicketModal(true);
                                            }}
                                        >
                                            환불
                                        </Button> */}
                                    </div>

                                </div>
                            </Item>
                        </Col>

                    ))}

                    {!isLoading && orders.length === 0 ? <div className="d-flex justify-content-center" style={{ alignItems: 'center', height: 150 }}>티켓구매 내역 없음</div> : null}
                </Col>

                {/* <PurchaseTicketModal
                    setVisible={setTicketModal}
                    visible={ticketModal}
                    info={selectOrder}
                    setInfo={setSelectOrder}
                    navigate={navigate}
                    needRefresh={setdata}
                /> */}
            </Row>
        </React.Fragment>
    );
}


export default ShoppingPurchaseList;