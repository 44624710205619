import React, { useRef } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { SUBDOMAIN } from "../../assets/meta/meta";
import { getAccessToken } from "../../pages/Authentication/auth";
import { BACKEND_HOST } from "../../api";
import { useUserStore } from "../../store/store";

const Profile = (props) => {
  const [permission] = useUserStore((state) => [state.permission]);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div style={{ minWidth: "200px" }} className="border w-25 rounded">
        <Link to={`/notice`} className="btn btn-link fw-medium text-decoration-none text-dark shadow-none">
          <i className="bx bx-bell fs-22"></i>
        </Link>
        <div className="w-100 mb-2 d-flex justify-content-center">
          <i className={props.profileImo} style={{ fontSize: "35px" }} />
        </div>
        <div className="w-100 border-top my-2 d-flex justify-content-between px-2">
          <Button onClick={() => navigate("/my-page")} outline className="btn btn-ghost-info" color="info">
            마이페이지
          </Button>
          <Link to="/" onClick={props.logOut} className="btn btn-link fw-medium text-decoration-none text-dark">
            로그아웃
          </Link>
        </div>
        {!!permission && permission.permission > 0 ? (
          <Button onClick={() => (window.location.href = `https://${SUBDOMAIN}crm.amazingticket.site/`)} outline className="btn btn-ghost-success w-100" color="info">
            관리
          </Button>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default Profile;
