import React from "react";
import styled from "styled-components";
// import { InputExample, InputSizing, FileInput, InputGroup, InputGroupSizing, MultipleInputs, ButtonsCheckboxesRadiosGroup, ButtonsWithDropdowns, CustomForms } from './BasicElementCode';

//import Components
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, UncontrolledDropdown } from 'reactstrap';
import readXlsxFile from 'read-excel-file'
import { getAccessToken } from "../Authentication/auth";
import { BACKEND_HOST } from "../../api";


export default function AddReserve() {
    const [data, setData] = React.useState([]);
    const [results , setResults] = React.useState([]);

    const readFile = (file) => {
        // const xlsxFile = require("read-excel-file/node");
        console.log(file[0].name)
        readXlsxFile(file[0]).then((sheets) => {
            setData(sheets);
        });
    }

    const postData = (id) => {
        const accessToken = getAccessToken();
        console.log(data[id], id);
        fetch(
            `${BACKEND_HOST}/package/add-reserv-prior/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    'data' : data[id]
                })
                }
            )
            .then((response) => response.json())
            .then(data=>setResults([...results, id]))
            .catch((e) => alert('전송 실패'));
        }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="live-preview">
                                        <Row className="align-items-center g-3">
                                            <Col lg={4}>
                                                <div>
                                                    <Label htmlFor="formFile" className="form-label">Default File Input Example</Label>
                                                    <p className="text-muted">Use <code>input</code> attribute with <code>type="file"</code> tag for default file input.</p>
                                                    <Input className="form-control" type="file" id="formFile" accept=".xlsx"
                                                        // ref={(ref) => this.fileUpload = ref}
                                                        onChange={(e) => readFile(e.target.files)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                                {data.length ? 
                                <table>
                                    <thead>
                                        {data[0].map((val, key) => {
                                            return <th key={key}>{val}</th>
                                        })
                                        }
                                        <th>Actions</th>
                                    </thead>
                                    <tbody>
                                        {data.map((val, key) => {
                                        if (key === 0) return null
                                        return <tr key={key}>{val.map((subval, key) => {
                                            return <td key={key}>{subval}</td>
                                        })}
                                    <td><button style={{backgroundColor: results.includes(key) ? 'green': null}} onClick={()=>{postData(key)}}>{results.includes(key) ? '성공':'전송'}</button></td>
                                        </tr>
                                    })}
                                    
                                    </tbody>
                                </table> : null}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}