import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation, useParams, useRoutes } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Tooltip,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    UncontrolledCarousel,
    InputGroup,
    InputGroupText,
} from "reactstrap";

import classnames from "classnames";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { DetailImgModal, DetailInfoModal, DetailUseAdviceModal } from "../../Components/Common/ManagerModal"
import { BACKEND_HOST } from "../../api";
import Navbar from "../../Components/Common/navbar";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

function ShoppingDetail(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    // const product = location.state?.product;

    const [product, setProduct] = useState(location.state?.product);

    const [quantity, setQuantity] = useState(1)

    // const [detailImageEditModal, setDetailImageEditModal] = useState(false);
    // const [detailInfoModal, setDetailInfoModal] = useState(false);
    // const [detailUseAdviceModal, setDetailUseAdviceModal] = useState(false);

    const [customActiveTab, setcustomActiveTab] = useState("1");
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    React.useEffect(() => {
        console.log("shop product id", id)
        fetch(`${BACKEND_HOST}/shop/product/${id}/`)
            .then((response) => response.json())
            .then((data) => {
                setProduct(data);
            })
            .catch(e => console.log(e));
    }, [id]);
    useEffect(() => {
        console.log("product", product)
        if (product?.quantity) setQuantity(product.quantity)
    }, [product])

    return (
        <React.Fragment>
            <div className="layout-wrapper landing">
                <Navbar />
                <div>
                    <Container fluid style={{ minHeight: "100vh" }}>

                        <Row>
                            <Col lg={12}>
                                {/* {(!!props.permission && props.permission.permission > 0) ?
                            <Button className="btn btn-soft-warning mb-3 border-0 position-absolute top-25 end-0"
                                style={{ zIndex: "2" }}
                                onClick={() => setDetailImageEditModal(!detailImageEditModal)}
                            >
                                상세이미지 수정
                            </Button>
                            : null
                        } */}
                                <Card>
                                    <CardBody className="px-0">
                                        <Row className="gx-lg-5">
                                            <Col xl={4} md={12} className="mx-auto">
                                                <div className="product-img-slider sticky-side-div">
                                                    {
                                                        (product?.images?.length) ?
                                                            <UncontrolledCarousel
                                                                interval={4000}
                                                                items={product.images ? product.images.map(img => ({
                                                                    src: `${img.image}`,
                                                                    altText: img.name,
                                                                    caption: img.name,
                                                                    key: img.id
                                                                })) : []}
                                                            /> :
                                                            <img src={`${product.thumbnail}`} style={{ width: "100%", height: "100%", resize: "contain" }} />
                                                    }
                                                </div>
                                            </Col>

                                            <Col xl={8}>
                                                <div className="mt-xl-2 mt-5">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h2>{product.name}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="product-meta">
                                                        <ul className="list-unstyled mb-0">
                                                            {/* <li className="mb-1">
                                                                <i className="uil uil-clipboard-notes me-1"></i>
                                                                <b>카테고리</b> : {product.category}
                                                            </li> */}
                                                            <li className="mb-3">
                                                                {product.summery || ""} <span className="" style={{ color: "gray" }}>(<b>남은수량</b> : {product.remaining_stock})</span>
                                                            </li>
                                                            {product.no_sale_price ? <li className="mb-1 d-flex">
                                                                <h5 className="mb-0" style={{ textDecorationLine: "line-through" }}>
                                                                    {new Intl.NumberFormat().format(product.no_sale_price)}원
                                                                </h5>
                                                                <h5 className="mb-0 ms-2" style={{ color: "red" }}>
                                                                    {Math.round(product.sale_rate) || "0"}%
                                                                </h5>
                                                            </li> : null}
                                                            <li className="mb-1">
                                                                <h2 className="text-black mb-0">
                                                                    <b>{new Intl.NumberFormat().format(product.price)}원</b>
                                                                </h2>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="product-content mt-5">
                                                        {/* <h5 className="fs-14 mb-3">Information :</h5> */}

                                                        <Nav tabs className="nav-tabs-custom nav-primary">
                                                            <NavItem>
                                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1" })} onClick={() => toggleCustom("1")}>
                                                                    상세정보
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2" })} onClick={() => toggleCustom("2")}>
                                                                    상품고시정보
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                        {/* 이거 없애면됨 */}
                                                        <TabContent
                                                            activeTab={customActiveTab}
                                                            className="pt-4"
                                                            id="nav-tabContent"
                                                        >
                                                            <TabPane
                                                                id="nav-detail"
                                                                tabId="1"
                                                            >
                                                                {/* {!!props.permission && props.permission.permission > 0 ?
                                                            <Button className="btn btn-soft-info mb-3 border-0 position-absolute top-25 end-0"
                                                                style={{ zIndex: "2" }}
                                                                onClick={() => setDetailInfoModal(!detailInfoModal)}
                                                            >
                                                                상세정보 수정
                                                            </Button>
                                                            : null
                                                        } */}
                                                                {product.detail_img ? <div style={{
                                                                    width: "100%",
                                                                    marginBottom: "20px",
                                                                }}>
                                                                    <img src={`${product.detail_img}`} style={{ width: "100%" }} />
                                                                </div> : null}
                                                                <div dangerouslySetInnerHTML={{ __html: product.descript }} />
                                                            </TabPane>
                                                            <TabPane
                                                                id="nav-speci"
                                                                tabId="2"
                                                            >
                                                                {/* {!!props.permission && props.permission.permission > 0 ?
                                                            <Button className="btn btn-soft-info mb-3 border-0 position-absolute top-25 end-0"
                                                                style={{ zIndex: "2" }}
                                                                onClick={() => setDetailUseAdviceModal(!detailUseAdviceModal)}
                                                            >
                                                                이용안내 수정
                                                            </Button>
                                                            : null
                                                        } */}
                                                                <div dangerouslySetInnerHTML={{ __html: product["info_notice"] }} />
                                                                {/* <div>{JSON.stringify(product["info_notice_list"])}</div> */}
                                                                <div>
                                                                    {product["info_notice_list"].map((item, idx) => {   // 이용안내 리스트
                                                                        return (
                                                                            <div key={idx} style={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-start",
                                                                                alignItems: "center",
                                                                                // borderBottom: "1px solid #dee2e6",
                                                                                padding: "8px 0",
                                                                                gap: "8px"

                                                                            }}>
                                                                                <div>{item.key}</div>
                                                                                <div>{item.value}</div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>

                                                    <div style={{ height: "80px" }}></div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="position-fixed d-flex flex-row justify-content-center"
                            style={{ bottom: "20px", left: "calc(50vw + 6px)", width: "calc(100vw - 40px)", transform: "translate(-50%)", zIndex: 999, }}>
                            <div style={{ width: "40%", display: "inline-block", paddingInline: "8px" }}>
                                {/* <InputGroup>
                                    <InputGroupText addonType="prepend">수량</InputGroupText>
                                    <Input value={quantity} type="number" step={1} min={1} onChange={(e) => { setQuantity(e.currentTarget.value) }} />
                                </InputGroup> */}
                                <QuantityButtonInput size={28} quantity={quantity} onChange={(value) => {
                                            if (value > product.remaining_stock) {
                                                alert("구매 가능 개수를 초과하였습니다.")
                                                return;
                                            }
                                            setQuantity(value)
                                        }} />
                            </div>
                            <Button
                                color="danger"
                                className="btn fw-bold fs-15"
                                style={{ width: "60%" }}
                                size="lg"
                                onClick={() => {
                                    navigate(`/order-shop`, {
                                        state: {
                                            products: [{ ...product, quantity: quantity }],
                                        }
                                    })
                                }}
                            >
                                구매하기
                            </Button>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShoppingDetail;