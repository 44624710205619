import React, { useEffect } from "react";
import Navbar from "../Components/Common/navbar";
import { Container } from "reactstrap";
import { isMobile, isAndroid, isIOS } from "react-device-detect";

function MainAppDownload({ path }) {
  const [defaultLink, setDefaultLink] = React.useState("#");

  const android_link = "https://play.google.com/store/apps/details?id=com.yargeu.AmazingCamp&hl=ko-KR";
  const ios_link = "https://apps.apple.com/us/app/amzingticket/id6447417241";

  useEffect(() => {
    if (isMobile) {
      if (isAndroid) {
        setDefaultLink(android_link);
      } else if (isIOS) {
        setDefaultLink(ios_link);
      }
    }
  }, []);

  const contentWidth = window.innerWidth > 540 ? "540px" : "100%";

  return (
    <div className="layout-wrapper landing">
      <Container fluid style={{ backgroundColor: "white", justifyContent: "center" }}>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          {/* <a href={defaultLink} target="_self" rel="noreferrer" style={{ border: "none" }}>
            <img
              src="https://total.amazingticket.site/media/amazingticket_app_banner.jpg"
              // width={window.innerWidth > 540 ? "540px" : "100%"}
              style={{ width: contentWidth }}
              alt="app download"
            />
          </a> */}
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px", marginBottom: "10px" }}>
          <div style={{ width: contentWidth, textAlign: "center" }}>
            <span style={{ fontSize: "22px", textAlign: "center", fontFamily: "Gmarket-M" }}>어메이징캠프 앱에서 예약 가능합니다 :{")"}</span>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0", marginBottom: "30px" }}>
          <div style={{ width: contentWidth, display: "flex", justifyContent: "center", alignItems: "center" }}>
            {!isIOS ? (
              <a href={android_link}>
                <img height="90px" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </a>
            ) : null}
            {!isAndroid ? (
              <a href={ios_link}>
                <img height="61px" alt="Download on the App Store" src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png" />
              </a>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MainAppDownload;
