import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Button } from "reactstrap";

import { SITE_ID } from "../../../assets/meta/meta";
import { FooterModal, FooterSnsEditModal, FooterSnsAddModal } from "../../../Components/Common/ManagerModal";
// Import Images
import logolight from "../../../assets/images/logo-light.png";
import { BACKEND_HOST } from "../../../api";

const Footer = (props) => {
  const navigate = useNavigate();

  const [visibleFooterEditModal, setVisibleFooterEditModal] = React.useState(false);
  const [visibleFooterSnsEditModal, setVisibleFooterSnsEditModal] = React.useState(false);
  const [visibleFooterSnsAddModal, setVisibleFooterSnsAddModal] = React.useState(false);

  const [snsLinkList, setSnsLinkList] = React.useState([]);
  const [siteInfo, setSiteInfo] = React.useState({});

  useEffect(() => {
    fetch(`${BACKEND_HOST}/sites/camps/${SITE_ID}/`)
      .then((response) => response.json())
      .then((data) => {
        setSiteInfo(data);
        setSnsLinkList(data["sns_links"]);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <React.Fragment>
      <footer className="custom-footer py-3 position-relative " style={{ borderTop: "1px solid #e5e7eb", marginTop: "100px" }}>
        <Container>
          <Row style={{ marginTop: "20px" }}>
            <Col lg={5} className="">
              <div>
                <div dangerouslySetInnerHTML={{ __html: siteInfo.footer }} style={{ fontFamily: "Gmarket-M", lineHeight: "28px", color: "#404040" }} />
                {/* <div>{!!props.permission && props.permission.permission}</div> */}
              </div>
              {!!props.permission && props.permission.permission > 0 ? (
                <Button className="btn btn-soft-warning mb-3 position-absolute top-50 end-0" style={{ color: "#404040" }} onClick={() => setVisibleFooterEditModal(!visibleFooterEditModal)}>
                  footer 내용 수정하기
                </Button>
              ) : null}
              <div>
                {/* service policy & privacy policy */}
                <ul className="list-unstyled footer-list mt-4">
                  <li className="list-inline-item">
                    <a href="https://total.amazingticket.site/service-policy/" className="" target="_blank" style={{ color: "#404040", fontFamily: "Gmarket-M" }}>
                      서비스 이용약관
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://total.amazingticket.site/agree/" className="" target="_blank" style={{ color: "#404040", fontFamily: "Gmarket-M" }}>
                      개인정보처리방침
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={7} className="ms-lg-auto">
              {!!props.permission && props.permission.permission > 0 ? (
                <div style={{ zIndex: "3" }} className="position-absolute top-50 end-0">
                  <Button className="btn btn-soft-warning me-2" style={{ color: "#404040" }} onClick={() => setVisibleFooterSnsEditModal(!visibleFooterSnsEditModal)}>
                    SNS 수정
                  </Button>
                  <Button className="btn btn-soft-info" style={{ color: "#404040" }} onClick={() => setVisibleFooterSnsAddModal(!visibleFooterSnsAddModal)}>
                    SNS 추가
                  </Button>
                </div>
              ) : null}
              <Row>
                {/* sns링크가 들어갈 부분 */}
                <div className="text-sm-end mt-3 mt-sm-0">
                  <ul className="list-inline mb-0 footer-social-link">
                    {/* sns링크가 들어갈 부분 */}
                    {snsLinkList.length !== 0 ? (
                      snsLinkList.map((item, idx) => {
                        return (
                          <li key={idx} className="list-inline-item">
                            <a href={item.url} className="avatar-xs d-block">
                              <div className="avatar-title rounded-circle opacity-50 bg-primary p-3">
                                <span className="text-light">
                                  <i className={`ri-${item.sns}-fill ri-2x`} />
                                </span>
                              </div>
                            </a>
                          </li>
                        );
                      })
                    ) : (
                      <div style={{ height: 60 }}></div>
                    )}
                  </ul>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* Updating Footer Modal */}
      <FooterModal setVisible={setVisibleFooterEditModal} visible={visibleFooterEditModal} origin={siteInfo.footer} site={siteInfo.id} navigate={navigate} />
      <FooterSnsEditModal setVisible={setVisibleFooterSnsEditModal} visible={visibleFooterSnsEditModal} setSnsList={setSnsLinkList} snsList={snsLinkList} navigate={navigate} />
      <FooterSnsAddModal setVisible={setVisibleFooterSnsAddModal} visible={visibleFooterSnsAddModal} navigate={navigate} />
    </React.Fragment>
  );
};

export default Footer;
