import React from "react";

import Navbar from "../../../Components/Common/navbar";
import Home from "./home";
import Client from "./client";
import Footer from "./footer";
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";
import { useUserStore } from "../../../store/store";
import MainAppDownload from "../../MainAppDownload";

const Index = ({ isWebPurchase, isWebPurchaseTicket }) => {
  const [permission] = useUserStore((state) => [state.permission]);

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <Navbar activeBar={0} permission={permission} />
        {console.log("web", isWebPurchase)}
        {console.log("webt", isWebPurchaseTicket)}
        {!isWebPurchase && !isWebPurchaseTicket && <MainAppDownload />}
        <Home permission={permission} />
        <Footer permission={permission} />
        <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
          <i className="ri-arrow-up-line"></i>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Index;
