import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Label, CardBody, CardHeader, Input, Button, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from "react-select";

import MyEditor from "../../../Components/Common/MyEditor";
import { getAccessToken, getUserId } from "../../Authentication/auth";

import { BACKEND_HOST } from "../../../api";
import { SITE_ID } from "../../../assets/meta/meta";
import ImagesUploader from "./ImagesUploader";
import { useUserStore } from "../../../store/store";

const InquiryFormSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [permission] = useUserStore((state) => [state.permission]);
  const user = useUserStore((state) => state.user);

  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const [mapAddress, setMapAddress] = React.useState("");
  const [phoneNum, setPhoneNum] = React.useState("");
  const [userFullName, setUserFullName] = React.useState("");

  const [phoneError, setphoneError] = useState(false);

  const [groupKey, setGroupKey] = useState(Math.random().toString(16).slice(2));
  const [images, setImages] = useState([]);

    const [modeChoices, setModeChoices] = useState([
        {
            value: "2",
            label: "문의"
        },
        // {
        //     value: "3",
        //     label: "리뷰"
        // },
        // {
        //     value: "1",
        //     label: "가맹점 문의"
        // },
        {
            value: "4",
            label: "공지사항"
        },
        {
            value: "5",
            label: "자주묻는질문"
        },
        {
            value: "6",
            label: "분실물"
        },
        // {
        //     value: "7",
        //     label: "기타"
        // },
    ]) 
    
    const [mode, setMode] = useState(modeChoices[0])

    useEffect(() => {
        if (user && (user.permission === "스탭" || user.permission === "관리자") && !!permission) {
            // console.log("permission", permission)
        } else if (user) {
            if(modeChoices.find(_mode => _mode.value === "4"))
            setModeChoices((prev) => {
                return [
                    {
                        value: "2",
                        label: "문의"
                    },
                ]
            })
        }

    if (user) {
      setUserFullName(user.username);
      setPhoneNum(user.phone);
    }
  }, [user, permission]);

  const fillStates = (data) => {
    setTitle(data.title);
    setContent(data.content);

    setImages(data.images);
    setGroupKey(data.image_group_key);
    setMapAddress(data.address);
    setPhoneNum(data.phone_num);
    setMode(modeChoices.find((_mode) => _mode.value === data.mode));
  };

  const checkData = () => {
    let regExp = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
    let result = regExp.test(phoneNum);
    if (phoneNum && !result) setphoneError(true);
    else setphoneError(false);

    if (!title || !content || !userFullName) {
      // if (!title || !mapAddress || !content || !result || !userFullName) {
      return null;
    }

    const userId = getUserId();
    if (userId === null) navigate(`/`);
    const data = {
      site: SITE_ID,
      user: userId,
      title: title,
      address: mapAddress,
      content: content,
      image_group_key: groupKey,
      images: images.map((_image) => _image.id),
      phone_num: phoneNum,
      user_full_name: userFullName,
      mode: mode.value,
    };

    return data;
  };

  const [isSending, setIsSending] = useState(false);

  const sendData = () => {
    const data = checkData();
    console.log("sendData", data);

    if (data === null) {
      alert("입력한 데이터가 정확한지 확인해 주세요.");
      return;
    } else {
      const accessToken = getAccessToken();
      if (!accessToken) {
        alert("로그인이 필요한 서비스입니다.");
        navigate(`/login`);
        return;
      }
      if (accessToken) {
        setIsSending(true);

        fetch(`${BACKEND_HOST}/inquiry/article-write/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("inquiry/article-write", data);

            alert("문의 등록 완료");
            resetForms();
            navigate(`/inquiry`);
          })
          .catch((e) => console.log(e))
          .finally(() => setIsSending(false));
      }
    }
  };

  const updateData = () => {
    const data = checkData();
    if (data === null) {
      alert("제목과 내용 모두 입력해주세요.");
      return;
    } else {
      const accessToken = getAccessToken();
      if (accessToken) {
        setIsSending(true);
        fetch(`${BACKEND_HOST}/inquiry/article-write/${id}/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            alert("수정 완료");
            resetForms();
            navigate(`/inquiry`);
          })
          .catch((e) => console.log(e))
          .finally(() => setIsSending(false));
      }
    }
  };

  const onImageUploaded = (data) => {
    setImages(data);
  };

  React.useEffect(() => {
    if (id !== "0") {
      const accessToken = getAccessToken();
      if (accessToken) {
        fetch(`${BACKEND_HOST}/inquiry/articles/${id}/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("inquiry/articles", data);
            fillStates(data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      resetForms();
    }
  }, [id]);

  const resetForms = () => {
    setGroupKey(Math.random().toString(16).slice(2));
    setTitle("");
    setContent("");
    setMapAddress("");
    setPhoneNum("");
  };

  useEffect(() => {
    if (phoneNum.length === 10) {
      setPhoneNum(phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (phoneNum.length === 13) {
      setPhoneNum(phoneNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
    }
  }, [phoneNum]);

  return (
    <div className="mt-4">
      <Container>
        <Row className="g-4 mb-3">
          <div className="col-sm-auto">
            <div>
              <h4 className="card-title mb-0 flex-grow-1">문의 등록 및 수정</h4>
            </div>
          </div>
          <div className="col-sm-3 ms-auto"></div>
        </Row>

        <Row className="justify-content-center">
          <Col lg={12}>
            <Card id="customerList">
              <CardBody>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="article-mode" className="form-label">
                        분류
                      </Label>
                      <Select
                        value={mode}
                        onChange={(value) => {
                          setMode(value);
                        }}
                        options={modeChoices}
                        name="article-mode"
                        id="article-mode"
                      ></Select>
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="title" className="form-label">
                        제목(*)
                      </Label>
                      <Input type="text" className="form-control" placeholder="제목" id="title" value={title} onChange={(e) => setTitle(e.target.value)} maxLength="100" />
                    </div>
                    {/* <div className="mb-3">
                                            <Label htmlFor="mapAddress" className="form-label">주소</Label>
                                            <Input type="text" className="form-control" placeholder="주소입력" id="mapAddress" value={mapAddress} onChange={(e) => (setMapAddress(e.target.value))} maxLength="200" />
                                        </div> */}
                    {/* <div className="mb-3">
                                            <Label htmlFor="userFullName" className="form-label">이름(*)</Label>
                                            <Input type="text" className="form-control" placeholder="이름입력" id="userFullName" value={userFullName} onChange={(e) => (setUserFullName(e.target.value))} maxLength="50" />
                                        </div> */}
                    {/* <div className="mb-3">
                                            <Label
                                                htmlFor="inquiry-phone"
                                                className="form-label"
                                            >
                                                휴대폰번호
                                            </Label>
                                            <Input
                                                value={phoneNum}
                                                onChange={(e) => {
                                                    setPhoneNum(e.currentTarget.value)
                                                }}
                                                type="text"
                                                className={`form-control ${phoneError ? "border-danger" : ""}`}
                                                id="inquiry-phone"
                                                placeholder="휴대폰번호"
                                            />
                                            {phoneError ? <span style={{ color: "red" }}>전화번호 양식이 정확하지 않습니다.</span> : null}
                                        </div> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12} className="mb-3" style={{ paddingLeft: "28px" }}>
            <ImagesUploader groupKey={groupKey} userId={getUserId()} onUploaded={onImageUploaded} />
          </Col>

          <Col lg={12}>
            <Card id="customerList">
              <CardHeader>
                <h4 className="card-title mb-0">내용</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <MyEditor value={content} onChange={setContent} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={12} className="mb-5">
            {isSending && (
              <div className="d-flex justify-content-sm-end gap-2">
                <Button className="btn-soft-success add-btn me-1" disabled>
                  <Spinner size="sm" color="light" /> 등록중
                </Button>
              </div>
            )}
            {!isSending && (
              <div className="d-flex justify-content-sm-end gap-2">
                {id !== "0" ? (
                  <Button
                    className="btn-soft-success add-btn me-1"
                    onClick={() => {
                      updateData();
                    }}
                    id="create-btn"
                  >
                    수정하기
                  </Button>
                ) : (
                  <Button
                    className="btn-soft-success add-btn me-1"
                    onClick={() => {
                      sendData();
                    }}
                    id="create-btn"
                  >
                    등록하기
                  </Button>
                )}
                <Button
                  className="btn-soft-danger add-btn me-1"
                  onClick={() => {
                    navigate(`/inquiry`);
                  }}
                  id="cancel-btn"
                >
                  취소하기
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InquiryFormSection;
